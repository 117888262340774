import React from "react";
import Utils from "../../utils";
import moment from "moment";

const BubbleMessages = (props) => {

	return (
		<>
		{props.messages.map((message, i, messages) => {
      console.log(message)
            let isGroup = false;
            if (i > 0) {
              isGroup = messages[i - 1].sender === message.sender;
            }
            const isLastGroupMessage = isGroup && (!messages[i + 1] || (messages[i + 1] && messages[i + 1].sender !== message.sender));
            const isFirstGroupMessage = (!messages[i - 1] || messages[i - 1].sender !== message.sender) && (messages[i + 1] && messages[i + 1].sender === message.sender);

            return (
              <div
                key={i}
                className={`message ${('to' in message) ? 'me' : 'other'} ${isGroup ? 'group' : ''} ${isLastGroupMessage ? 'last-group' : ''} ${isFirstGroupMessage ? 'first-group' : ''}`}
              >
                <div className="bubble">{message.message}</div>
                <span class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500"> {Utils.getMomentText(moment(message.timestamp).format("X"))}</span>
              </div>
            );
          })}
		</>
	)
}

export default BubbleMessages;