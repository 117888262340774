import React, { Component , createRef, Fragment } from "react";
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Pagetitle from '../components/Pagetitle';
import Appfooter from '../components/Appfooter';
import PropertyCard from "../components/PropertyCard";
import i18n from "../i18n";
import request from "../request";
import * as L from "leaflet";
import Load from '../components/Load'
import Rightchat from "../components/Rightchat";
import Popupchat from "../components/Popupchat";
import '../css/leaflet.css'
import '../css/MarkerCluster.Default.css'
import '../css/MarkerCluster.css'
import SearchProperties from "../components/SearchProperties"
import uriHelper from "../uri_helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import ActiveFilters from "../components/ActiveFilters";
import 'leaflet-draw/dist/leaflet.draw.css'
import InteractiveMap from "../components/InteractiveMap";
import ModalGeneric from "../components/ModalGeneric";
import Select from 'react-select';
import Swal from "sweetalert2";

class Properties extends Component {

  static defaultProps = {
    center: {
      lat: 39.6642211,
      lng: 20.85371
    },
    zoom: 15
  };

  constructor(props) {
    super(props);

    this.editRef = createRef(null)
    this.customMarkerIcon = L.icon({
      iconUrl: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAApCAYAAADAk4LOAAAFgUlEQVR4Aa1XA5BjWRTN2oW17d3YaZtr2962HUzbDNpjszW24mRt28p47v7zq/bXZtrp/lWnXr337j3nPCe85NcypgSFdugCpW5YoDAMRaIMqRi6aKq5E3YqDQO3qAwjVWrD8Ncq/RBpykd8oZUb/kaJutow8r1aP9II0WmLKLIsJyv1w/kqw9Ch2MYdB++12Onxee/QMwvf4/Dk/Lfp/i4nxTXtOoQ4pW5Aj7wpici1A9erdAN2OH64x8OSP9j3Ft3b7aWkTg/Fm91siTra0f9on5sQr9INejH6CUUUpavjFNq1B+Oadhxmnfa8RfEmN8VNAsQhPqF55xHkMzz3jSmChWU6f7/XZKNH+9+hBLOHYozuKQPxyMPUKkrX/K0uWnfFaJGS1QPRtZsOPtr3NsW0uyh6NNCOkU3Yz+bXbT3I8G3xE5EXLXtCXbbqwCO9zPQYPRTZ5vIDXD7U+w7rFDEoUUf7ibHIR4y6bLVPXrz8JVZEql13trxwue/uDivd3fkWRbS6/IA2bID4uk0UpF1N8qLlbBlXs4Ee7HLTfV1j54APvODnSfOWBqtKVvjgLKzF5YdEk5ewRkGlK0i33Eofffc7HT56jD7/6U+qH3Cx7SBLNntH5YIPvODnyfIXZYRVDPqgHtLs5ABHD3YzLuespb7t79FY34DjMwrVrcTuwlT55YMPvOBnRrJ4VXTdNnYug5ucHLBjEpt30701A3Ts+HEa73u6dT3FNWwflY86eMHPk+Yu+i6pzUpRrW7SNDg5JHR4KapmM5Wv2E8Tfcb1HoqqHMHU+uWDD7zg54mz5/2BSnizi9T1Dg4QQXLToGNCkb6tb1NU+QAlGr1++eADrzhn/u8Q2YZhQVlZ5+CAOtqfbhmaUCS1ezNFVm2imDbPmPng5wmz+gwh+oHDce0eUtQ6OGDIyR0uUhUsoO3vfDmmgOezH0mZN59x7MBi++WDL1g/eEiU3avlidO671bkLfwbw5XV2P8Pzo0ydy4t2/0eu33xYSOMOD8hTf4CrBtGMSoXfPLchX+J0ruSePw3LZeK0juPJbYzrhkH0io7B3k164hiGvawhOKMLkrQLyVpZg8rHFW7E2uHOL888IBPlNZ1FPzstSJM694fWr6RwpvcJK60+0HCILTBzZLFNdtAzJaohze60T8qBzyh5ZuOg5e7uwQppofEmf2++DYvmySqGBuKaicF1blQjhuHdvCIMvp8whTTfZzI7RldpwtSzL+F1+wkdZ2TBOW2gIF88PBTzD/gpeREAMEbxnJcaJHNHrpzji0gQCS6hdkEeYt9DF/2qPcEC8RM28Hwmr3sdNyht00byAut2k3gufWNtgtOEOFGUwcXWNDbdNbpgBGxEvKkOQsxivJx33iow0Vw5S6SVTrpVq11ysA2Rp7gTfPfktc6zhtXBBC+adRLshf6sG2RfHPZ5EAc4sVZ83yCN00Fk/4kggu40ZTvIEm5g24qtU4KjBrx/BTTH8ifVASAG7gKrnWxJDcU7x8X6Ecczhm3o6YicvsLXWfh3Ch1W0k8x0nXF+0fFxgt4phz8QvypiwCCFKMqXCnqXExjq10beH+UUA7+nG6mdG/Pu0f3LgFcGrl2s0kNNjpmoJ9o4B29CMO8dMT4Q5ox8uitF6fqsrJOr8qnwNbRzv6hSnG5wP+64C7h9lp30hKNtKdWjtdkbuPA19nJ7Tz3zR/ibgARbhb4AlhavcBebmTHcFl2fvYEnW0ox9xMxKBS8btJ+KiEbq9zA4RthQXDhPa0T9TEe69gWupwc6uBUphquXgf+/FrIjweHQS4/pduMe5ERUMHUd9xv8ZR98CxkS4F2n3EUrUZ10EYNw7BWm9x1GiPssi3GgiGRDKWRYZfXlON+dfNbM+GgIwYdwAAAAASUVORK5CYII=',
      iconSize: [25, 41],
      iconAnchor: [12.5, 41]
    });

    this.state = {
      properties: [],
      cordes: [],
      loaded: false,
      drawing: false,
      fetchingSearchResults: false,
      page: 1,
      end_of_data: false,
      show_search: false,
      filters: [],
      shape: [],
      cross_id: false,
      showModal: false,
      search_title: '',
      saved_searches: [],
      search_polygon: null
    };

    this.searchTimeout = null;

    this.mapRef = React.createRef();
    this.getProperties = this.getProperties.bind(this);
  }

  componentDidMount() {
    
    uriHelper.init()
    uriHelper.set_component_view('listings')
    if (uriHelper.get('cross', false) !== false) {
      this.setState({
        cross_id: uriHelper.get('cross', false)
      })
    }

    this.fetchSavedSearches()
    this.getProperties(true)

    // this._drawLocalEdit()

    this.unlistenLocale = i18n.onChangeLocale(() => {
      this.forceUpdate();
    });
    
    this.onPropertiesScroll = (e) => {
      if( e.target.scrollTop >= (e.target.scrollHeight - e.target.offsetHeight - 1))
      {
        this.changePage();
      }
    };
    document.querySelector(".properties-wrapper").addEventListener('scroll', this.onPropertiesScroll);
  }

  componentWillUnmount() {
    document.querySelector(".properties-wrapper").removeEventListener('scroll', this.onPropertiesScroll);
    uriHelper.clear()
    this.unlistenLocale()
  }

  changePage() {
    const page = uriHelper.get('page', 0)
    uriHelper.set('page', (page + 1), false)
    this.getProperties(false)
  }

  async getProperties(page_reset = true) {
    return new Promise((resolve) => {
      if (!this.state.end_of_data || page_reset) {
        let get_map_data = 0
        if (page_reset) {
          get_map_data = 1
          uriHelper.set('page', 1)
        }
          

        const uri = uriHelper.uri_to_string()
        
        if (this.searchTimeout) {
          window.clearTimeout(this.searchTimeout)
        }
        
        this.searchTimeout = setTimeout(() => {
          request.post(`${uri}/mapData/${get_map_data}`, {
            cordes: this.state.shape
          }).then((response) => {
            const properties = page_reset ? response.data.listings : this.state.properties.concat(response.data.listings)
            
            const stateData = {
              properties: properties,
              filters: response.data.filters,
              loaded: true,
              end_of_data: response.data.listings.length < 12
            }
            if (get_map_data === 1) {
              stateData.cordes = response.data.cordes_data.cordes
              stateData.cordes_hash = response.data.cordes_data.hash
            }

            this.setState({
              ...stateData
            }, () => {
              resolve(true)
            });
          });
        }, 100)
        
      }
    })
      
  }

  setShow(show) {
    this.setState({
      show_search: show
    })
  }

  removeFilter(key, value) {
    this.setState({
      filters: this.state.filters.filter(f => f.key !== key)
    })
  }

  // map edit methods 
  _onEditPath(e) {
    // console.log(e)
  }
  _onCreate = (e) => {
    this.setState({
      shape: e
    }, () => this.getProperties(true))
  }
  _onDeleted() {

  }

  saveSearchInit = () => {
    this.setState({
      showModal: true,
      search_title: ''
    })
  } 
  
  saveSearch = () => {
    const uri = uriHelper.uri_to_string()
  
    request.post('/saved_searches/properties', {
      uri: uri,
      shape: this.state.shape,
      title: this.state.search_title
    }).then(response => {
      Swal.fire({
        title: i18n.t('success'),
        text: i18n.t('search_has_been_saved'),
        icon: 'success'
      }).then(rsp => {
        this.setState({
          showModal: false,
          search_title: ''
        })
      })
    })
  }

  fetchSavedSearches = () => {  
    request.get('/saved_searches/properties').then(response => {
      this.setState({
        saved_searches: response.data
      })
    })
  }

  setSelectedSearch = (id) => {
    const selected_search = this.state.saved_searches.filter(s => s.id === id)[0]

    if (selected_search) {
      uriHelper.init_uri(selected_search.uri)
      uriHelper.set_uri()
      this.setState({
        shape: selected_search.shape,
        search_polygon: selected_search.shape
      }, () => {
        this.getProperties()
      })
    }
  }
    
  render() {
    const page_title = this.state.cross_id ? i18n.t('properties_crossings_for_request') + ` #${this.state.cross_id}` : i18n.t('properties_page_title')
    return (
      <Fragment>
        <Header />
        <Leftnav />

        <div className="main-content right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0" style={{maxWidth: "100%"}}>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <Pagetitle 
                      title={page_title}
                    >
                      <div className="search-controls">
                        <Select
                          className="select-element mr-5"
                          classNamePrefix="select"
                          placeholder={i18n.t('load_saved_search')}
                          
                          isSearchable={true}
                          name="categories"
                          options={this.state.saved_searches.map(s => {
                            return {
                              label: `${s.name} (${s.total} ${i18n.t('results')})`,
                              value: s.id
                            }
                          })}
                          onChange={(e) => {this.setSelectedSearch(e.value)}}
                        />
                        {
                          (this.state.filters.length > 1 || (this.state.shape && this.state.shape.length > 0)) &&
                          <button className='btn btn-secondary text-white mr-5 rounded-xl' onClick={() => this.saveSearchInit()}>
                            {i18n.t('save_search')}
                          </button>
                        }
                        <button className="btn btn-primary text-white mr-5 rounded-xl" onClick={() => this.setState({show_search: true})}>
                          <FontAwesomeIcon icon={faMagnifyingGlass} /> {i18n.t('filters')}
                        </button>
                      </div>
                      <ActiveFilters filters={this.state.filters} onChange={(key, value) => this.removeFilter(key, value)} />
                      

                      <ModalGeneric
                        show={this.state.showModal}
                        handleClose={() => this.setState({
                          showModal: false
                        })}
                        header={<h4>{i18n.t('save_search')}</h4>}
                        footer={<div className="card-body p-0 d-flex space-between open-house-footer align-center">
                              <div className="d-flex">
                                <button onClick={() => this.saveSearch()} className="bg-success btn-action btn-accept p-2 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-600">{i18n.t('save')}</button>
                                <button onClick={() => this.setState({
                          showModal: false
                        })}className="bg-grey btn-action btn-accept p-2 ms-2 z-index-1 rounded-3 font-xsssss text-uppercase fw-600">{i18n.t('button_cancel_request')}</button>
                              </div>
                            </div>}
                      >
                        <div className="card w-100 border-0 p-4 mb-3">
                          <div className="card-body mt-2 p-0">
                            <div className="form-group p-relative">
                              <label className="mb-2">{i18n.t('set_a_search_title')}</label>
                              <input 
                                type="text" 
                                className='form-control' 
                                onChange={(e) => this.setState({
                                  search_title: e.target.value
                                })}
                                placeholder={i18n.t('search_title')}
                                ref={this.searchRef}
                              />
                            </div>
                          </div>
                        </div>
                      </ModalGeneric>
                    </Pagetitle>
                  </div>
                  <div className="col-xl-6 chat-left scroll-bar properties-wrapper">
                    <SearchProperties
                      onchange={() => this.getProperties(true)}
                      setShow={(show) => this.setShow(show)}
                      show={this.state.show_search}
                    />
                    <div className="row ps-2 pe-2">
                      {this.state.properties.map((data , index) => (
                        <PropertyCard 
                          key={index} 
                          data={data}
                        />
                      ))}
                    </div>
                    <Load end={this.state.end_of_data}/>
                  </div>

                  <div className="col-xl-6 ps-0 d-none d-xl-block">
                    <div className="card w-100 border-0 shadow-none rounded-3 border-0 mb-4 overflow-hidden h-100">
                      <InteractiveMap 
                        cordes={this.state.cordes}
                        hash={this.state.cordes_hash}
                        _onCreate={this._onCreate}
                        initialPolygon={this.state.search_polygon ? this.state.search_polygon : null}
                      />
                      {/* <button onClick={this.handlePolygonClick}>Σχεδιάστε την περιοχή αναζήτησης</button> */}
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <Rightchat />
        <Popupchat />
        <Appfooter /> 
      </Fragment>
    );
  }
}

export default Properties;