import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import settings from '../settings';
import i18n from '../i18n';
import Darkbutton from '../components/Darkbutton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBug } from '@fortawesome/free-solid-svg-icons';
import '../css/header.css';

class Appfooter extends Component {

      toggleChatContactsWindow(e) {
        e.preventDefault()
        e.stopPropagation()
        settings.set('sideContactsListOpen', !settings.sideContactsListOpen);
      }

    render() {
        return (
            <div className="app-footer border-0 shadow-lg bg-primary-gradiant">
                 <Link to="/home" className="nav-content-bttn nav-center"><i className="feather-home"></i></Link>
                <Link to="/notifications" className="nav-content-bttn"><i className="feather-bell"></i></Link>
                <Link to="" id="footer-chat-show" className="nav-content-bttn" data-tab="chats"><i className="feather-message-square" onClick={(e) => this.toggleChatContactsWindow(e)}></i></Link>
                <Link to="/" component={Darkbutton} className="nav-content-bttn"><i className="feather-moon" style={{padding: 20}}></i></Link>
                <Link to="" className="nav-content-bttn">
                    <i className="feather-globe" onClick={() => {
                        if(i18n.getLocale() === 'en')
                            i18n.setLocale('el')
                        else
                            i18n.setLocale('en')
                        }}>
                    </i>
                </Link>


                <Link to="/profile" className="nav-content-bttn"><i className="feather-user"></i></Link>
                
            </div>
        );
    }
}

export default Appfooter;