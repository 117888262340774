import React, { Component , Fragment } from "react";
import { Link } from 'react-router-dom';

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import i18n from "../i18n";
import storage from "../storage";
import request from "../request";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
// import Logout from '../components/Logout';

class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      me: null
    };
  }

  componentDidMount() {
    i18n.onChangeLocale(() => {
      this.forceUpdate();
    });

    request.get('whoami').then(response => {
      let date_1 = new Date(response.data.realtors_access_until);
      let date_2 = new Date();

      const days = (date_1, date_2) => {
          let difference = date_1.getTime() - date_2.getTime();
          let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
          return TotalDays;
      }
      console.log(days(date_1, date_2))
      this.setState({
        me: {
          ...response.data,
          days_left: days(date_1, date_2)
        }
      })
    })

  }

  render() {
    return (
      <Fragment> 
        <Header />
        <Leftnav />
        <Rightchat />

        <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left">
              <div className="middle-wrap">
                <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                  <div className="card-body p-lg-5 p-4 w-100 border-0">
                    <div className="row">
                      <div className="col-lg-12">
                        <h1 className="mb-4 font-xxl fw-700 mont-font mb-lg-5 mb-4 font-md-xs">{i18n.t('settings')}</h1>
                        <h2 className="nav-caption fw-600 font-xssss text-grey-500 mb-2">{i18n.t('general')}</h2>
                        <ul className="list-inline mb-4">
                          <li className="list-inline-item d-block border-bottom me-0">
                            <Link to="/accountinformation" className="pt-2 pb-2 d-flex align-items-center">
                              <i className="btn-round-md bg-primary-gradiant text-white feather-home font-md me-3"></i>
                              <h3 className="fw-600 font-xsss mb-0 mt-0">{i18n.t('account_information')}</h3>
                              <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                            </Link>
                          </li>
                          {/* <li className="list-inline-item d-block border-bottom me-0">
                            <Link to="/contactinformation" className="pt-2 pb-2 d-flex align-items-center">
                              <i className="btn-round-md bg-gold-gradiant text-white feather-map-pin font-md me-3"></i>
                              <h3 className="fw-600 font-xsss mb-0 mt-0">{i18n.t('saved_address')}</h3><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                            </Link>
                          </li> */}
                          {/* <li className="list-inline-item d-block me-0">
                            <Link to="/socialaccount" className="pt-2 pb-2 d-flex align-items-center">
                              <i className="btn-round-md bg-red-gradiant text-white feather-twitter font-md me-3"></i>
                              <h3 className="fw-600 font-xsss mb-0 mt-0">{i18n.t('social_account')}</h3><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                            </Link>
                          </li> */}
                        </ul>
                        <h2 className="nav-caption fw-600 font-xsss text-grey-500 mb-2">{i18n.t('account')}</h2>
                        <ul className="list-inline mb-4">
                          <li className="list-inline-item d-block  me-0">
                            <Link to="/password" className="pt-2 pb-2 d-flex align-items-center">
                              <i className="btn-round-md bg-blue-gradiant text-white feather-inbox font-md me-3"></i>
                              <h3 className="fw-600 font-xsss mb-0 mt-0">{i18n.t('password')}</h3><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                            </Link>
                          </li>
                          {
                            this.state.me && this.state.me.realtors_payment_month > 0 &&
                            <li className="list-inline-item d-block  me-0">
                              <Link to="/renew_plan" className="pt-2 pb-2 d-flex align-items-center">
                                <i className="btn-round-md bg-blue-gradiant text-white feather-inbox font-md me-3"></i>
                                <h3 className="fw-600 font-xsss mb-0 mt-0">{i18n.t('renew_subscription')} ({i18n.locale === 'el' ? `Απομένουν ${this.state.me.days_left} μέρες χρήσης` : `${this.state.me.days_left} days left until the end of subscription`})</h3><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                              </Link>
                            </li>
                          }
                          <li className="list-inline-item d-block  me-0">
                            <a href="https://documenter.getpostman.com/view/10464469/2s93eVVsid" target="_blank" className="pt-2 pb-2 d-flex align-items-center">
                              <i className="btn-round-md bg-blue-gradiant text-white feather-inbox font-md me-3"></i>
                              <h3 className="fw-600 font-xsss mb-0 mt-0">{i18n.t('access_to_API')}</h3><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                            </a>
                          </li>
                        </ul>
                        <h2 className="nav-caption fw-600 font-xsss text-grey-500 mb-2">{i18n.t('notifications')}</h2>
                        <ul className="list-inline mb-4">
                          <li className="list-inline-item d-block border-bottom me-0">
                            <Link to="/notifications" className="pt-2 pb-2 d-flex align-items-center">
                              <i className="btn-round-md bg-gold-gradiant text-white feather-bell font-md me-3"></i>
                              <h3 className="fw-600 font-xsss mb-0 mt-0">{i18n.t('show_notifications')}</h3><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                            </Link>
                          </li>
                          <li className="list-inline-item d-block border-bottom me-0">
                            <Link to="/notification-settings" className="pt-2 pb-2 d-flex align-items-center">
                              <i className="btn-round-md bg-primary-gradiant text-white feather-settings font-md me-3"></i>
                              <h3 className="fw-600 font-xsss mb-0 mt-0">{i18n.t('configure_notifications')}</h3>
                              <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                            </Link>
                          </li>
                        </ul>
                        <h2 className="nav-caption fw-600 font-xsss text-grey-500 mb-2">{i18n.t('other')}</h2>
                        <ul className="list-inline">
                          <li className="list-inline-item d-block border-bottom me-0">
                            <Link to="/help" className="pt-2 pb-2 d-flex align-items-center">
                              <i className="btn-round-md bg-primary-gradiant text-white feather-help-circle font-md me-3"></i>
                              <h3 className="fw-600 font-xsss mb-0 mt-0">{i18n.t('help')}</h3><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                            </Link>
                          </li>
                          <li className="list-inline-item d-block me-0">
                            {/* <Link to="/" onClick={Logout} className="pt-2 pb-2 d-flex align-items-center"> */}
                            <Link to="/login" className="pt-2 pb-2 d-flex align-items-center">
                              <i className="btn-round-md bg-red-gradiant text-white feather-lock font-md me-3"></i>
                              <h3 className="fw-600 font-xsss mb-0 mt-0">{i18n.t('logout')}</h3><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Popupchat />
        <Appfooter /> 
      </Fragment>
    );
  }
}

export default Settings;