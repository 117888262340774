import React, { Component , Fragment } from "react";
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import Rightchat from "../components/Rightchat";
import request from "../request";
import i18n from "../i18n";
import history from '../helpers/history';
import config from "../config";
import Utils from "../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faTrash, faUpload } from "@fortawesome/free-solid-svg-icons";
import SortableList from "../components/SortableList";
import Swal from "sweetalert2";
import LoadingEvent from "../components/LoadingEvent";
import LoadingIcon from "../components/LoadingIcon";

class PropertyMedia extends Component {

  constructor(props) {
    super(props);

    this.fileRef = React.createRef(null)


    this.state = {
        id: null,
        images: [],
        uploading: false
    };
    
  }

  storeProperty() {
    request.post(`properties/store`, this.state.images).then(response => {
    })
  }

  componentDidMount () {
    const id = this.props.match.params.id

    if (id) {
      request.get(`property_images/${id}`, this.state.images).then(async response => {
        this.setImageState(response.data)
        this.setState({
            id: id
        })
      })
    }
    else {
        history.push('/manage_properties')
    }
  }

  async setImageState(images) {
    const imgs = await Promise.all(images.map(async image => {
        const exists = await Utils.image_exists(config.property_images_url + image.image_name)
        if (exists) {
            return (image)
        }
        else {
            return ({
                ...image,
                image_name: 'not_found.jpg'
            })
        }
        
    }))

    this.setState({
        images: imgs,
        uploading: false
    })
  }

  handleImageOrder(ids) {
    if (ids.length > 0 && ids.length === this.state.images.length) {
        let found_change = false;
        ids.forEach((id, i) => {
            if (this.state.images[i].id != id) {
                found_change = true;
                return;
            }
        })

        if (found_change) {
            request.post(`change_property_image_order/${this.state.id}`, {
                ids: ids
            }).then(() => {
                const new_pos = []
                // ids.forEach((id, i) => {
                //     new_pos.push(this.state.images.filter(img => parseInt(img.image_id) === parseInt(id) )[0])
                // })
                // this.setState({
                //     images: new_pos
                // })
            })
        }
    }
  }

  changeImageThumbnail(image) {
    request.patch(`change_property_thumbnail/${this.state.id}`, {
        image: image.image_name
    }).then(response => {
        if (response.status === 200) {
            this.setState({
                images: this.state.images.map(img => {
                    if (img.image_id === image.image_id) {
                        return {
                            ...img,
                            thumbnail: '1'
                        }
                    }

                    return {
                        ...img,
                        thumbnail: '0'
                    }
                })
            })
        }
    })
  }

  deleteImage(image) {
    Swal.fire({
        title: i18n.t('are_you_sure'),
        text: i18n.t('you_are_going_to_delete_this_image'),
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: i18n.t('delete'),
        cancelButtonText: i18n.t('cancel')
    }).then(response => {
        if(response.isConfirmed) {
            request.delete(`property_images/${image.image_id}`).then(res => {
              
                if (res.status === 200) {
                    Swal.fire({
                        title: i18n.t('success'),
                        text: i18n.t('image_deleted'),
                        icon: 'success',
                    }).then(() => {
                        this.setState({
                            images: this.state.images.filter(img => img.image_id !== image.image_id)
                        })
                    })
                }
                else {
                    Swal.fire({
                        title: i18n.t('something_went_wrong'),
                        text: i18n.t('try_again'),
                        icon: 'warning',
                    })
                }
            })
        }
    })
  }

  async uploadImages() {
    const files = Array.from(this.fileRef.current.files)
    const encoded_files = await this.tobase64Handler(files)
    
    if (encoded_files.length > 0) {
        this.setState({
          uploading: true
        })
        request.put(`property_images/${this.state.id}`, {
            images: encoded_files
        }).then((response) => {
          this.setState({
            uploading: false
          })
            if (response.status === 200) {
                Swal.fire({
                    title: i18n.t('success'),
                    text: i18n.t('images_uploaded_succesfully'),
                    icon: 'success',
                }).then(() => {
                    this.setImageState(response.data)
                })
            }
        })
    }
  }

  toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };

  async tobase64Handler(files) {
    const filePathsPromises = [];
    files.forEach(file => {
      filePathsPromises.push(this.toBase64(file));
    });
    const filePaths = await Promise.all(filePathsPromises);
    // const mappedFiles = filePaths.map((base64File) => ({ selectedFile: base64File }));
    return filePaths;
  }

  initUpload() {
    this.fileRef.current.click()
  }

  render() {

    return (
      <Fragment> 
        
        <Header />
        <Leftnav />
          <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom">
              <div className="middle-sidebar-left pe-0" style={{maxWidth: "100%"}}>
                <div className="container">
                <LoadingEvent show={this.state.uploading}/>
                  <div className="row sticky-top form-header">
                    <div className="col-md-12">
                        {
                        this.state.id &&
                        <div>
                            <h3>{i18n.t('update_property')} #{this.state.id}</h3>
                        </div>
                        }
                      <div className="property-form-steps">
                        <div className="property-form-step active">
                          <span className="step">{i18n.t('property_media')}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mt-4 mb-4">
                        <button className="btn btn-primary text-white mt-4" onClick={() => this.initUpload()}>{i18n.t('upload_image')} <FontAwesomeIcon icon={faUpload} /></button>
                        <input type="file" ref={this.fileRef} className="hidden" onChange={(files) => {this.uploadImages(files)}} multiple />
                    </div>
                    <div className="col-md-12 mt-4 mb-4 edit-media-images">
                        <div className="images-wrapper">
                            {
                              this.state.images && this.state.images.length > 0 &&
                                <SortableList items={
                                    this.state.images.map((image, i) => {
                                        return {
                                                id: image.image_id,
                                                item: (
                                                    <div className="property-image">
                                                    <img src={`${config.property_images_url}${image.image_name}`} />
                                                    {
                                                        image.thumbnail === "1" && 
                                                        <span className="is-thumbnail"><FontAwesomeIcon icon={faStar}/></span>
                                                    }
                                                    <div className="property-image-actions">
                                                        <button className="btn btn-danger" onMouseDown={() => {this.deleteImage(image)}}><FontAwesomeIcon icon={faTrash}/></button>
                                                        <button className="btn btn-warning text-white" onMouseDown={() => {
                                                          // document.querySelectorAll('.is-thumbnail').forEach(function (elem) {
                                                          //   elem.remove();
                                                          // });
                                                          this.changeImageThumbnail(image)
                                                        }}><FontAwesomeIcon icon={faStar}/></button>
                                                    </div>
                                                </div>
                                                )
                                            }
                                        
                                    })
                                }
                                onChange={(ids) => this.handleImageOrder(ids)}
                                />
                            }
                        </div>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        <Rightchat />
        <Popupchat />
        <Appfooter /> 
      </Fragment>
    );
  }
}

export default PropertyMedia;