import axios from 'axios';
import i18n from './i18n';
import config from './config';
import storage from './storage';
import history from './helpers/history';

class Request {

  
  constructor() {
    this.excludeFrom401Redirect = ['/register', '/login', '/sso_failed', '/account_pending', '/terms'];
    this.apiBaseURL = config.apiBaseURL;

    // create an axios instance
    this.instance = axios.create({
      
    });
    // const token = localStorage.getItem('token') !== null && localStorage.getItem('token') != 'null' ? localStorage.getItem('token') : null
    // this.bearer = storage.token !== null && storage.token != 'null' ? storage.token : null;
    this.bearer = localStorage.getItem('token') !== null && localStorage.getItem('token') != 'null' ? localStorage.getItem('token') : null

    storage.onChange((key, value) => {
      if (key === 'token') {
        this.bearer = value
      }
    })
    // transform request
    this.instance.interceptors.request.use( (config) => {
      
      if( this.bearer ) {
        config.headers.Authorization = `Bearer ${this.bearer}`;
      }
      
      return config;
    }, function (error) {
      return (error);
    });
  
    // transform response
    this.instance.interceptors.response.use( (response) => {
      return response;
    }, (error) => {
      
      if (401 === error.response.status && !this.excludeFrom401Redirect.includes(history.location.pathname)) {
        history.push('/login');
      }
      if (424 === error.response.status && !this.excludeFrom401Redirect.includes(history.location.pathname)) {
        history.push('/renew_plan');
      }
      return (error.response);
    });
  }

  request(method, path, data) {
    const url = new URL(`${this.apiBaseURL}${path}`);
    // url.searchParams.append('login', localStorage.loginId || 0);
    // url.searchParams.append('locale', i18n.getLocale() || 'el');
    return this.instance[method.toLowerCase()](url.toString(), data);
  }

  get(path) {
    return this.request('GET', path);
  }

  post(path, data) {
    return this.request('POST', path, data);
  }

  put(path, data) {
    return this.request('PUT', path, data);
  }

  patch(path, data) {
    return this.request('PATCH', path, data);
  }

  delete(path, data) {
    return this.request('DELETE', path, data);
  }

  login(path = 'login', data) {
    return this.request('POST', path, data)
    
  }
}

const request = new Request();
window.request = request;

export default request;