import React, { Component , createRef, Fragment } from "react";
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Pagetitle from '../components/Pagetitle';
import Appfooter from '../components/Appfooter';
import i18n from "../i18n";
import request from "../request";
import * as L from "leaflet";
// import 'leaflet/dist/leaflet.css';
// import 'leaflet.markercluster';
import Load from '../components/Load'
import Rightchat from "../components/Rightchat";
import Popupchat from "../components/Popupchat";
import '../css/leaflet.css'
import '../css/MarkerCluster.Default.css'
import '../css/MarkerCluster.css'
import uriHelper from "../uri_helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import ActiveFilters from "../components/ActiveFilters";
// import 'leaflet/dist/leaflet.css'
import 'leaflet-draw/dist/leaflet.draw.css'
import InteractiveMap from "../components/InteractiveMap";
import RealtorCard from "../components/RealtorCard";
// import 'leaflet.markercluster/dist/MarkerCluster.css'
// import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

class RealtorsListings extends Component {

  static defaultProps = {
    center: {
      lat: 39.6642211,
      lng: 20.85371
    },
    zoom: 15
  };

  constructor(props) {
    super(props);

    this.state = {
	  realtors: [],
      cordes: [],
      loaded: false,
      drawing: false,
      fetchingSearchResults: false,
      page: 1,
      end_of_data: false,
      show_search: false,
      filters: [],
      shape: [],
      cross_id: false
    };

    this.searchTimeout = null;

    this.mapRef = React.createRef();
    
    this.onDeleteFriend = this.onDeleteFriend.bind(this);
    this.onAddFriend = this.onAddFriend.bind(this);
    this.getRealtors = this.getRealtors.bind(this);
  }

  componentDidMount() {
    
    uriHelper.init()
    uriHelper.set_component_view('social_members')
    if (uriHelper.get('cross', false) !== false) {
      this.setState({
        cross_id: uriHelper.get('cross', false)
      })
    }
    this.getRealtors(true)

    // this._drawLocalEdit()

    this.unlistenLocale = i18n.onChangeLocale(() => {
      this.forceUpdate();
    });
    
    this.onRealtorsScroll = (e) => {
      if( e.target.scrollTop >= (e.target.scrollHeight - e.target.offsetHeight - 1))
      {
        this.changePage();
      }
    };
    document.querySelector(".properties-wrapper").addEventListener('scroll', this.onRealtorsScroll);
  }

  componentWillUnmount() {
    document.querySelector(".properties-wrapper").removeEventListener('scroll', this.onRealtorsScroll);
    uriHelper.clear()
    this.unlistenLocale()
  }

  onAddFriend(data) {
    const f = this.state.realtors.slice(0);
    const i = f.findIndex((friend) => parseInt(friend.id) === parseInt(data.connection_receiver));
    f[i].connection_id = data.connection_id;
    f[i].connection_sender = data.connection_sender;
    f[i].connection_receiver = data.connection_receiver;
    f[i].connection_status = data.connection_status;
    this.setState({
      realtors: f,
    });
  }

  onDeleteFriend(friendshipId, data) {
    const f = this.state.realtors.slice(0);
    const i = f.findIndex((friend) => parseInt(friend.connection_id) === parseInt(friendshipId));
    f[i].connection_id = data.connection_id;
    f[i].connection_sender = data.connection_sender;
    f[i].connection_receiver = data.connection_receiver;
    f[i].connection_status = data.connection_status;
    this.setState({
      ...this.state,
      realtors: f,
    });
  }

  changePage() {
    const page = uriHelper.get('page', 0)
    uriHelper.set('page', (page + 1), false)
    this.getRealtors(false)
  }

  async getRealtors(page_reset = true) {
    return new Promise((resolve) => {
      if (!this.state.end_of_data || page_reset) {
        let get_map_data = 0
        if (page_reset) {
          get_map_data = 1
          uriHelper.set('page', 1)
        }
          

        const uri = uriHelper.uri_to_string()
        
        if (this.searchTimeout) {
          window.clearTimeout(this.searchTimeout)
        }
        
        this.searchTimeout = setTimeout(() => {
          request.post(`${uri}/mapData/${get_map_data}`, {
            cordes: this.state.shape
          }).then((response) => {
            const realtors = page_reset ? response.data.listings : this.state.realtors.concat(response.data.listings)
            
            const stateData = {
			  realtors: realtors,
              filters: response.data.filters,
              loaded: true,
              end_of_data: response.data.listings.length < 12
            }
            if (get_map_data === 1) {
              stateData.cordes = response.data.cordes_data.cordes
              stateData.cordes_hash = response.data.cordes_data.hash
            }

            this.setState({
              ...stateData
            }, () => {
              resolve(true)
            });
          });
        }, 100)
        
      }
    })
      
  }

  setShow(show) {
    this.setState({
      show_search: show
    })
  }

  removeFilter(key, value) {
    this.setState({
      filters: this.state.filters.filter(f => f.key !== key)
    })
  }

  // map edit methods 
  _onEditPath(e) {
    // console.log(e)
  }
  _onCreate = (e) => {
    this.setState({
      shape: e
    }, () => this.getRealtors(true))
  }
  _onDeleted() {

  }
    
  render() {
    return (
      <Fragment>
        <Header />
        <Leftnav />

        <div className="main-content right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0" style={{maxWidth: "100%"}}>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <Pagetitle 
                      title={i18n.t('social_members_title')}
                    >
                    </Pagetitle>
                  </div>
                  <div className="col-xl-6 chat-left scroll-bar properties-wrapper">
                    
                    <div className="row ps-2 pe-2">
                      {this.state.realtors.map((data , index) => (
                        <div className="col-md-6">
                          <RealtorCard 
                            key={index} 
                            data={data}
                            onAddFriend={this.onAddFriend}
                            onDeleteFriend={this.onDeleteFriend}
                          />
                        </div>
                      ))}
                    </div>
                    <Load end={this.state.end_of_data}/>
                  </div>

                  <div className="col-xl-6 ps-0 d-none d-xl-block">
                    <div className="card w-100 border-0 shadow-none rounded-3 border-0 mb-4 overflow-hidden h-100">
                      <InteractiveMap 
                        cordes={this.state.cordes}
                        hash={this.state.cordes_hash}
                        _onCreate={this._onCreate}
						            isRealtor
                      />
                      {/* <button onClick={this.handlePolygonClick}>Σχεδιάστε την περιοχή αναζήτησης</button> */}
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <Rightchat />
        <Popupchat />
        <Appfooter /> 
      </Fragment>
    );
  }
}

export default RealtorsListings;