import React, { Component , createRef, Fragment } from "react";
import { Link } from 'react-router-dom';
import i18n from "../i18n";
import { Button } from "react-bootstrap";
import logoWhite from '../assets/images/real-status-logo-white.png'
import realstatusLogo from '../assets/images/RealStatus_logo.png'
import smallLogo from '../assets/images/realstatus-logo-symbol.png'
import request from "../request";
import history from '../helpers/history';
import storage from "../storage";

class Login extends Component {
	constructor(props) {
		super(props);
		
		this.passwordRef = createRef(null)
		this.state = {
		  email: '',
		  password: '',
		  wrong_credentials: false,
		  message: {}
		};
		const lightTheme = "theme-light"
		const darkTheme = "theme-dark"
		let theme
	
		if (localStorage) {
			theme = localStorage.getItem("theme")
		}
	
		if (theme === lightTheme || theme === darkTheme) {
			document.body.classList.add(theme)
		} else {
			document.body.classList.add(darkTheme)
		}
	  }

	componentDidMount() {
		i18n.onChangeLocale(() => {
		  this.forceUpdate();
		});
		const token = localStorage.getItem('token') !== null && localStorage.getItem('token') != 'null' ? localStorage.getItem('token') : null
		if (token) {
		storage.set('token', token)

		request.get(`whoami`).then((response) => {
			if (response.status === 200) {
				history.push('/')
			}
		});
		}
		const redirectMessage = localStorage.getItem('login_message') ? JSON.parse(localStorage.getItem('login_message')) : null
		// localStorage.setItem('login_message', JSON.stringify({
		// 	message: i18n.t('registered_success'),
		// 	type: 'success'
		// }))
		if (redirectMessage) {
			this.setState({
				message: {
					message: i18n.t(redirectMessage.message),
					type: redirectMessage.type
				}
			})
			localStorage.removeItem('login_message') 
		}
		
		
	}

	async onLogin() {
		if (this.state.email != '' && this.state.password != '') {
			let response = await this.props.onLogin(this.state.email, this.state.password )
			
			if (!response) {
				this.setState({
					wrong_credentials: true
				})

				setTimeout(() => {
					this.setState({
						wrong_credentials: false
					})
				}, 5000)
			} 
		}
	}

	checkKeyPressed(evt) {
		evt = evt || window.event;
    	const charCode = evt.keyCode || evt.which;

		if (charCode === 13) {
			this.onLogin()
		}
	}

	render() {
		return (
			<Fragment> 
				<div className="main-wrap">
					<div className="nav-header bg-transparent shadow-none border-0">
						<div className="nav-top navigation-login-menu w-100 justify-content-xs-end justify-content-sm-end">
							{/* <img src="assets/images/realstatus-logo.png" className="app-logo ms-5" alt="Real Status Logo Symbol" height="70" /> */}
							{/* <button className="nav-menu me-0 ms-auto"></button> */}

							<Link to="/login" className="header-btn d-none d-lg-block background-light-blue fw-500 text-white font-xsss p-2 ms-auto w100 text-center lh-20 rounded-xl">{i18n.t('login')}</Link>
							<Link to="/register" className="header-btn d-none d-lg-block bg-secondary fw-500 text-white font-xsss p-2 ms-2 w100 text-center lh-20 rounded-xl">{i18n.t('register')}</Link>

							<span className="pointer p-2 text-center ms-3 btn-locale"
								onClick={() => {
									if(i18n.getLocale() === 'en')
										i18n.setLocale('el')
									else
										i18n.setLocale('en')
								}}
								>
								<i className="feather-globe"></i>
							</span>
						</div>
					</div>
					<div className="row">
						<div className="col-xl-5 d-none d-xl-flex p-0 vh-100 bg-primary align-items-center justify-content-center bg-cover">
							<div className="col-6 text-center">
								<img src={logoWhite} alt="Real Status Logo Symbol"/>
								{/* <span className="text-white fw-500 font-xsss">A Message for Login Page!</span> */}
							</div>
						</div>
						<div className="col-xl-7 login-wrapper vh-100 align-items-center bg-cover2 d-flex bg-white rounded-3 overflow-hidden">
							<div className="card shadow-xxs-2 border-0 ms-auto me-auto login-card">
								<div className="card-body rounded-0 text-left">
									<h1 className="fw-700 mb-3">{i18n.t('login_into_your_account')}</h1>

									<div>
										<div className="form-group icon-input mb-3">
											{
												(this.state.message && this.state.message.message) && 
												<div className={`alert ${this.state.message.type === 'success' ? 'alert-success' : 'alert-warning'}`}>
													{this.state.message.message} &nbsp;
												</div>
											}
											<div className={`alert alert-danger ${this.state.wrong_credentials ? '' : 'd-none'}`}>
												{i18n.t('Λάθος e-mail ή κωδικός πρόσβασης')}. {i18n.t('Ξεχάσατε τον κωδικό σας; Πατήστε')} &nbsp;
												<Link to="/forgot">{i18n.t('εδώ')}</Link>
											</div>
											
										</div>
										<div className="form-group icon-input mb-3">
											<i className="font-sm ti-email text-grey-500 pe-0"></i>
											<input 
												type="text" 
												value={this.state.email} 
												onChange={(e) => this.setState({email: e.target.value})} 
												onKeyUp={(e) => this.checkKeyPressed(e)}
												className="style2-input login-input ps-5 form-control text-grey-900 font-xsss fw-600" 
												placeholder={i18n.t('your_email_address')} 
											/>                        
										</div>
										<div className="form-group icon-input mb-1">
											<input
												ref={this.passwordRef}
												type="Password"
												className="style2-input login-input px-5 form-control text-grey-900 font-xss ls-3"
												placeholder={i18n.t('password')}
												value={this.state.password}
												onChange={(e) => this.setState({ password: e.target.value })}
												onKeyUp={(e) => this.checkKeyPressed(e)}
											/>
											<i className="font-sm ti-lock text-grey-500 pe-0"></i>
											<i 
											onMouseEnter={() => {
												this.passwordRef.current.type = 'text'
											}}
											onMouseLeave={() => {
												this.passwordRef.current.type = 'password'
											}}
											className="font-sm ti-eye pos-right preview-pass text-grey-500 pe-0"></i>
										</div>
										<div className="form-check text-left mb-3">
											<input type="checkbox" className="form-check-input mt-2" id="exampleCheck5" />
											<label className="form-check-label font-xsss text-grey-500">{i18n.t('remember_me')}</label>
											<Link to="/forgot" className="fw-600 font-xsss text-grey-700 mt-1 float-right">{i18n.t('forgot_your_password')}</Link>
										</div>
									</div>
									
									<div className="col-sm-12 p-0 text-left ">
										<div className="form-group mb-1 d-flex justify-content-end">
											<Button 
												className="text-center rounded-xl btn btn-primary text-white"
												onClick={() => {this.onLogin()}}
											>
												{i18n.t('login')}
											</Button>
										</div>
										<h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">{i18n.t('dont_have_account')} <Link to="/register" className="fw-700 ms-1">{i18n.t('register')}</Link></h6>
									</div>
									<div className="col-sm-12 p-0 text-center mt-2">
										
										<h6 className="mb-0 d-inline-block fw-500 font-xsss text-grey-500 mb-3">{i18n.t('sign_in_alternatives')} </h6>
										<div className="form-group mb-1"><a href="https://network.realstatus.net/sso/rs_login" className="form-control text-left style2-input text-white fw-600 bg-facebook border-0 p-0 mb-2 rounded-xl real-button"><img src={smallLogo} alt="icon" className="custom-sso-img-new mx-2" /> {i18n.t('sign_in_with_realstatus')}</a></div>
										{/* <div className="form-group mb-1"><a href="/register" className="form-control text-left style2-input text-white fw-600 bg-twiiter border-0 p-0 "><img src="assets/images/icon-3.png" alt="icon" className="ms-2 w40 mb-1 me-5" /> {i18n.t('sign_in_with_facebook')}</a></div> */}
									</div>
								</div>
							</div> 
						</div>
						
					</div>
				</div>
			</Fragment>
		);
	}
}

export default Login;