import React, {Component, Fragment} from "react";
import chat from '../chat';
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Pagetitle from '../components/Pagetitle';
import Appfooter from '../components/Appfooter';
import i18n from "../i18n";
import socket from '../socket';
import storage from "../storage";
import request from "../request";
import Bubble from "../components/Bubble/Bubble";
import { Link } from "react-router-dom";
import FullChatWrapper from "../components/FullChat/FullChatWrapper";

class Messages extends Component {

    constructor(props) {
        super(props) //kalei ton constructor tou parent
        this.state = {
            contacts: chat.contacts, //trexei prin na kanei to request
            active_contact: null,
            width:  800,
            height: 182,
            lastScrollHeight: 0
        } //arxikopoihsh twn objects ths klashs

        this.elementRef = React.createRef();
        // onClick={this.getS}
    }

    getContactFromUri() {
        const contact_id = this.props.match.params.id;

        if (contact_id) {
            const contacts = this.state.contacts.filter(c => parseInt(c.id) === parseInt(contact_id));
            if (contacts.length) {
                this.setActiveContact(contacts[0])
                
            }
        }
        else {
            this.setActiveContact(null)
        }
        
    }
    componentDidMount() { //kaleitai mia fora mono sto init
        this.unmountLocale = i18n.onChangeLocale(() => {
            this.forceUpdate();
        });

        this.clearChatChange = chat.onChange(() => {
            this.setState({
                ...this.state,
                contacts: chat.contacts,
            });
        });

        this.getContactFromUri()
        
    }

    componentDidUpdate(prevProps, prevState) { //kaleitai otan allazei to state h mia parametro

        const contact_id = this.props.match.params ? this.props.match.params.id : 0;
        const old_contact_id  = prevProps.match.params ? prevProps.match.params.id : 0;
        // if (!contact_id) return;

        if (
            contact_id === old_contact_id
        ) return;

        this.getContactFromUri()
    }

    setActiveContact(contact) {
        this.setState({
            active_contact: contact
        })
    }

    componentWillUnmount() {
        this.clearChatChange()
        this.unmountLocale()
    }

    openChatWindow(contact) {
        this.setState({
            active_contact: contact
        })
    }

    render() { 
        return (
            <Fragment>
                <Header />
                <div className="main-content right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0" style={{maxWidth: "100%"}}>
                            <div className='row'>
                                <div className="col-lg-3 col-12">
                                    <div className={`nav-wrap mt-2 right-scroll-bar active-sidebar black-b full-messages-list`}>
                                        <div className="middle-sidebar-right-content bg-white shadow-xss rounded-xxl">
                                            <div className="section full pe-3 ps-4 pt-4 position-relative feed-body">
                                                <h2 className="font-xsssss text-grey-500 fw-700 ls-3">{i18n.t('friends_c')}</h2> 
                                                    <div className="list-group list-group-flush">
                                                        {
                                                            this.state.contacts.map((contact, i) =>
                                                            <Link to={`/messages/${contact.id}`}>
                                                                    <div
                                                                        key={i}
                                                                        className={`item ${contact.newMessage ? 'new-message' : ''} bg-transparent list-group-item no-icon pe-0 ps-0 pt-2 pb-2 border-0 d-flex align-items-center`}
                                                                        // onClick={() => this.openChatWindow(contact)}
                                                                    >   
                                                                        <figure className="avatar float-left mb-0 me-2">
                                                                            <img src={`${contact.profile_image}`} alt="avater" className="w35 rounded-circle" />
                                                                        </figure>
                                                                        <h3 className={`${contact.newMessage && contact.newMessage === true ? 'fw-700' : ''} contact-user-wrapper mb-0 mt-0 me-2`}>
                                                                            <span className="font-xssss text-grey-600 d-block text-dark model-popup-chat pointer" >{contact.name}</span>
                                                                            {
                                                                            (contact.status) ?
                                                                            <span className="contact-status contact-status-online"></span> : 
                                                                            <span className="contact-status contact-status-offline"></span>
                                                                            }
                                                                        </h3>

                                                                        <span className={`${contact.status} ms-auto btn-round-xss`}></span>
                                                                    </div>
                                                                </Link> 
                                                            )
                                                        }
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-9 col-12">
                                    <div className='card w-100 d-block border-0 p-4 page-title-header'>
                                        <h2 className="fw-700 mb-0 mt-0 font-md text-grey-900 d-flex align-items-center">{i18n.t('messages')}</h2>
                                    </div>
                                    {
                                        this.state.active_contact != null ?
                                        <div className="chat-wrapper">
                                            
                                                <FullChatWrapper realtor={this.state.active_contact} isFull={true}/>
                                        </div>
                                        :  <div className="messages"></div>   
                                    }
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                
                <Appfooter /> 
            </Fragment>
        )
            
    }
}
export default Messages;