import React, { Component , createRef, Fragment } from "react";
import {
    Link,
  } from 'react-router-dom';
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import { faPlus, faAngleRight, faTrash } from "@fortawesome/free-solid-svg-icons";
    import { Tooltip } from '@mui/material';
  import Header from '../components/Header';
  import ImageUploadDialog from "../components/ImageUploadDialog";
  import Leftnav from '../components/Leftnav';
  import Rightchat from '../components/Rightchat';
  import Appfooter from '../components/Appfooter';
  import Popupchat from '../components/Popupchat';
  import Button from '../components/Button';
  import Load from '../components/Load';
  import i18n from "../i18n";
  import request from '../request';
  import storage from '../storage';
  import socket from '../socket';
  import Pagetitle from '../components/Pagetitle';
import Swal from "sweetalert2";
  
class ManageNetworks extends Component {
    constructor(props) {
        super(props)

        this.state = {
            networks: [],
            page: 1,
        }
    }

    componentDidMount() {

        this.getNetworks();
        this.unmountLocale = i18n.onChangeLocale(() => {
            this.forceUpdate();
          });

        this.onDocumentScroll = (e) => {
            const totalPageHeight = document.body.scrollHeight - 1; 
            const scrollPoint = window.scrollY + window.innerHeight;
            if(scrollPoint >= totalPageHeight) {
              this.showMoreNetworks();
            }
          };
          document.addEventListener('scroll', this.onDocumentScroll);
    }

    getNetworks(page) {
        const url = this.props.location.pathname.split('/').slice(-3)[0] && this.props.match.params.id ?
        `networks/fetch_edit/${this.props.location.pathname.split('/').slice(-3)[0]}/${this.props.match.params.id}/?page=${page || this.state.page}` :
        `networks/fetch_edit/?page=${page || this.state.page}`;
    
        return request.get(url).then((response) => {
          this.setState({
            
            networks: this.state.networks.concat(response.data),
            loaded: true,
            end: response.data.length < 10,
          });
        });
    }

    showMoreNetworks() {
        if(!this.state.end){
          const newPage = this.state.page + 1;
          this.getNetworks(newPage);
          this.setState({
            
            page: newPage,
          });
        }
    }

    changeNetworkImage(e) {
        const network = this.state.networks.findIndex(n => n.id == this.state.selectedNetwork);
        const networks = JSON.parse(JSON.stringify(this.state.networks));
        networks[network].image = e;
        this.setState({
          
          networks: networks
        });
      }
    
      handleClickImageDialogOpen = (a, b) => {
        this.setState({ 
          
          imageDialogOpen: true,
          imageType: a,
          selectedNetwork: b,
        });
      };
    
      handleClickImageDialogClose = () => {
        this.setState({ 
          
          imageDialogOpen: false,
          imageType: '',
          selectedNetwork: null, 
        });
      };

    componentWillUnmount() {
        i18n.clearOnChangeLocale();
        document.removeEventListener('scroll', this.onDocumentScroll);
    }

    getNetworkMembers(network) {
        return <>
          {
              network.members_count >= 1
              ? <li><a><img src={network.members[0].profile_image} alt={`User ${network.members[0].id}`} className="w30 d-inline-block img-circle" /></a></li>
              : ''
          }
          {
            network.members_count >= 2
              ? <li><a><img src={network.members[1].profile_image} alt={`User ${network.members[1].id}`} className="w30 d-inline-block img-circle" /></a></li>
              : ''
          }
          {
            network.members_count >= 3
              ? <li><a><img src={network.members[2].profile_image} alt={`User ${network.members[2].id}`} className="w30 d-inline-block img-circle" /></a></li>
              : ''
          }
          {
            network.members_count >= 4
              ? <li><a><img src={network.members[3].profile_image} alt={`User ${network.members[3].id}`} className="w30 d-inline-block img-circle" /></a></li>
              : ''
          }
          {
            network.members_count >= 5
              ? <li className="last-member">
                  <a className="bg-greylight fw-600 text-grey-500 font-xssss ls-3 text-center">+{network.members_count - 4}</a>
                </li>
              : ''
          }
        </>
    }

    deleteNetwork = (id) => {
      Swal.fire({
        title: i18n.t('are_you_sure'),
        text: i18n.t('you_are_going_to_delete_this_network'),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: i18n.t('cancel'),
        confirmButtonText: i18n.t('delete')
      }).then(answer => {
        if (answer.isConfirmed) {
          request.delete(`networks/${id}`).then(response => {
            if (response.status === 200) {
              Swal.fire({
                title: i18n.t('success'),
                text: i18n.t('network_deleted'),
                icon: 'success'
              })
              this.setState({
                networks: this.state.networks.filter(n => parseInt(n.id) !== parseInt(id))
              });
            }
            else {
              Swal.fire({
                title: i18n.t('something_went_wrong'),
                text: i18n.t('try_again_single'),
                icon: 'error'
              })
            }
          })
        }
        
      })
    }

    render() {
        return (
            <Fragment>
                <Header/>
                <Leftnav/>
                <Rightchat/>
                <div className="main-content right-chat-active">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0">
                        <div className="container">
                            <Pagetitle 
                                title={i18n.t('manage_networks')}
                                btn={
                                    <Link 
                                to="/networks/create_network"
                                className={`cursor-pointer d-inline-block ps-3 pe-3 lh-32 my-4 rounded-xl ls-2 text-white me-1 bg-primary`}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                                {i18n.t('create_new_network')}
                            </Link>
                                }
                            >
                            </Pagetitle>
                            <div className="row">
                                {this.state.networks.map((network , index) => {
                                //const hasActionsOn = network.actions;
                                const hasActionsOn = true;
                                const amOnNetwork  = network.joined;
                                const canJoinDirectly = !amOnNetwork && !network.private;
                                const hasPendingJoinRequest = network.pending;
                                const canRequestToJoin = !amOnNetwork && network.private && !hasPendingJoinRequest;
                                const amOwner = storage.me && (network.owner === storage.me.realtors_id);
                                const amMemberOrAdmin = !amOwner;
                                const networkIsPublic = !network.private;
                                const networkImage    = network.image ? network.image : `https://network.realstatus.net/images/network_image/default.jpg`;
                                const coverImage      = network.cover ? network.cover: `/assets/images/covers/abstract.jpg`;
                                return ( amOwner ?
                                    <div key={index} className="col-lg-6 col-md-6 col-12 pe-2 ps-2">
                                        <div className="card p-3 bg-white w-100 network-thumb hover-card border-0 shadow-xss position-relative rounded-xxl border-0 mb-3 overflow-hidden ">
                                            <div style={{position: 'relative', zIndex: 2}}>
                                                <Tooltip title={i18n.t('update_network_image')} placement="top">
                                                    <i onClick={(a, b)=>this.handleClickImageDialogOpen('network_image', network.id)}
                                                        className="text-grey-900 btn-round-md bg-greylight font-md feather-camera me-3 text-grey-500"
                                                        style={{position: 'absolute', top: '2%', right: '-2%', cursor: 'pointer'}}>
                                                    </i>
                                                </Tooltip>
                                            <div className="card-image">
                                                <div className="network-avatar">
                                                <Link to={`/network/${network.id}`}><img src={networkImage} alt="networkImage" className="rounded-3" /></Link>
                                                </div>
                                    
                                            </div>
                                            </div>
                                            <div className="fw-700 lh-3 font-xss">
                                            
                                            
                                            </div>
                                            <div className="card-body ps-0 pe-0 pb-0 z-index-1 text-center">
                                                <div className="text-break">
                                                {
                                                    (amOnNetwork || networkIsPublic)
                                                    ? <h4><Link to={`/network/${network.id}`}>{network.name}</Link></h4>
                                                    : <h4>{network.name}</h4>
                                                }
                                                </div>
                                                {
                                                    network.private
                                                    ? <div className='font-xsss text-grey-500'><i className="feather-lock"></i><span>{i18n.t('private')}</span></div>
                                                    : <div className='font-xsss text-grey-500'><i className="feather-globe"></i><span>{i18n.t('public')}</span></div>
                                                }
                                            
                                            </div>
                                            <div className="card-body p-0 z-index-1 text-center">
                                            
                                            </div>
                                            <div className="card-body d-flex p-0 z-index-1 justify-content-center align-items-center text-center">
                                                <span className="d-flex font-xsss fw-500 mt-2 lh-3 text-grey-500 bord-r position-relative pad-1512 text-center">
                                                    
                                                    
                                                    {
                                                    (amOnNetwork || networkIsPublic)
                                                        ? <Link to={`/network/${network.id}/properties`}><span className='alert-link font-xs'>{network.properties}</span><br/><span>{i18n.t('properties')}</span></Link>
                                                        : <div className="d-inline"><span className='alert-link font-xs'>{network.properties}</span><br/><span>{i18n.t('properties')}</span></div>
                                                    }
                                                    
                                                </span>
                                                <span className="d-flex font-xsss fw-500 mt-2 lh-3 text-grey-500 position-relative pad-1512 text-center">
                                                    {
                                                    (amOnNetwork || networkIsPublic)
                                                        ? <Link to={`/network/${network.id}/requests`}><span className='alert-link font-xs'>{network.requests}</span><br/><span>{i18n.t('requests')}</span></Link>
                                                        : <div className="d-inline"><span className='alert-link font-xs'>{network.requests}</span><br/><span>{i18n.t('requests')}</span></div>
                                                    }
                                                    
                                                </span>

                                                
                                            </div>
                                            <div className="card-body d-flex network-preview-actions">
                                                <button onClick={() => this.deleteNetwork(network.id)} className="d-inline-block rounded-xl border-none p-sm-2 mr-2 btn-danger text-white font-xssss">
                                                    {i18n.t('delete')} <FontAwesomeIcon icon={faTrash} />
                                                </button>

                                                <Link to={`/edit_network/${network.id}`} className="d-inline-block rounded-xl border-none p-sm-2 mr-2 bg-primary-gradiant text-white font-xssss">
                                                    {i18n.t('edit')} <FontAwesomeIcon icon={faAngleRight} />
                                                </Link>
                                                
                                            </div>
                                            <div className="network-cover-img" style={{backgroundImage: `url(${coverImage})`}}></div>
                                        </div>
                                    </div>
                                    : ''
                                
                                );
                                })}
                            </div>
                            <Load end={this.state.end}/>
                        </div>
                    </div>
                </div>
                </div>

        <Popupchat />
        <Appfooter /> 

            </Fragment>
        )
    }
}
export default ManageNetworks;