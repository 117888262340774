import React from 'react';
import i18n from '../i18n';
import request from '../request';
import Select from 'react-select';
import uriHelper from '../uri_helper';
import AsyncSelect from 'react-select/async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRemove } from '@fortawesome/free-solid-svg-icons';
import Utils from '../utils';


class SearchProperties extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: true,
	  categories: [],
	  subcategories: [],
	  locations: [{label: i18n.t('search_locations'), value: -1}],
	  heat: [],
	  selected_options: {
		category: null,
		subcategories: null,
		areas: null,
		for: null,
		priceFrom: '',
		priceTo: '',
		areaFrom: '',
		areaFrom: '',
	  }
    };

	this.setSelectedCategory = this.setSelectedCategory.bind(this)
	this.setSelectedValue = this.setSelectedValue.bind(this)
	this.locationSearch = this.locationSearch.bind(this)
	this.handleUriChange = this.handleUriChange.bind(this)
  }

  componentDidMount() {
	this.on_remove_uri = uriHelper.onRemove(this.handleUriChange)
	this.on_change_uri = uriHelper.onChange(this.initParams)
	request.get('all_lookups').then(response => {
		this.setState({
			categories: response.data.categories,
			heat: response.data.heat,
		}, () => {
			this.initParams()
		})
		

	})
  }

  componentWillUnmount() {
	this.on_remove_uri()
  }

  initParams = () => {
	const category = uriHelper.get('category', null)
	const subcategories = uriHelper.get('subcategories', null)
	const areas = uriHelper.get('areas', null)
	if (category) {
		this.getSubcategories(category, subcategories)
	}

	this.setState({
		selected_options: {
			...this.state.selected_options,
			category: category,
			for: uriHelper.get('for', null),
			priceFrom: uriHelper.get('priceFrom', ''),
			priceTo: uriHelper.get('priceTo', ''),
			areaFrom: uriHelper.get('areaFrom', ''),
			areaTo: uriHelper.get('areaTo', ''),
		}
	})
  }

  handleUriChange(key) {
	if (this.state.selected_options[key]) {
		this.setSelectedValue(key, uriHelper.get(key, null))
	}
	else {
		this.props.onchange()
	}
  }

  initAreas(areas) {
	const areas_str = areas.join('-')
	request.get(`locations_to_object/${areas_str}`).then(response => {
		this.setLocation(response.data.map(loc => {
			return {
				label:i18n.get_data('text', loc),
				value: loc.id
			}
		}))
	})
  }

  transformOption(raw) {
	if (Array.isArray(raw)) {
		return raw.map(r => {
			return {
				label: i18n.get_data('name', r),
				value: r.id
			}
		})
	}
	return {
		label: i18n.get_data('name', raw),
		value: raw.id
	}
  }

  setSelectedCategory(e) {
	
	uriHelper.set('category', e)
	uriHelper.set('subcategories', null)
	this.setState({
		selected_options: {
			...this.state.selected_options,
			category: e,
			subcategory: null
		}
	}, () => {
		this.getSubcategories(e)
	})

	this.props.onchange()
  }

  getSubcategories(category, values = null) {
	request.get(`lookups/categories/${category}`).then(response => {
		this.setState({
			subcategories: response.data,
			selected_options: {
				...this.state.selected_options,
				subcategories: values
			}
		})
	})
  }
  setLocation(e) {
	const values = e.map(loc => loc.value)
	uriHelper.set('areas', values)
	
	this.setState({
		locations: e.map(loc => {
			return {
				id: loc.value,
				name: loc.label,
				name_en: loc.label,
			}
		}),
		selected_options: {
			...this.state.selected_options,
			areas: values
		}
	})
	
	this.props.onchange()
  }

  setSelectedValue(key, value) {
	uriHelper.set(key, value)
	this.setState({
		selected_options: {
			...this.state.selected_options,
			[key]: value
		}
	})
	this.props.onchange()
  }

  getOption(arr, val) {
	
	if (val === null) return null;
	var f;

	if (Array.isArray(val)) {
		f = arr.filter(a => val.filter(v => {
			if (!isNaN(v)) {
				return parseInt(v) === parseInt(a.id)
			}
			else {
				return (v) == (a.id)
			}
		}).length > 0)
	}
	else {
		f = arr.filter(a => {
			if (!isNaN(val)) {
				return parseInt(val) === parseInt(a.id)
			}
			else {
				return (val) == (a.id)
			}
		})
	}

	if (f.length > 0) {
		return this.transformOption(f)
	}

	return null
  }

  locationSearch( searchText, x) {
	return new Promise(resolve => {
		if (searchText && searchText.length >= 3) {
			if (this.locationTimeout) window.clearTimeout(this.locationTimeout)
			this.locationTimeout = setTimeout(() => {
				Utils.searchAddress(searchText).then(response => {
					
						resolve(response.data.features.map(location => {
							return {
								label: location.properties.geocoding.label,
								value: `${location.geometry.coordinates[0]}-${location.geometry.coordinates[1]}`
							}
						}))
					
					
				})
			}, 1000)
		}
		else {
			resolve([{
				label: `${i18n.t('type_at_least')} ${3 - searchText.length} ${i18n.t('or_more_characters')}`, 
				value: -1,
				isDisabled: true
			}])
		}
	})
  }

  render() {
	const categories = this.state.categories.map(c => {
		return {
			label: i18n.get_data('name', c),
			value: c.id
		}
	});

	const subcategories = this.state.subcategories.map(c => {
		return {
			label: i18n.get_data('name', c),
			value: c.id
		}
	});

	const locations = this.state.locations.map(c => {
		return {
			label: i18n.get_data('name', c),
			value: c.id
		}
	});
	const category_value = this.getOption(this.state.categories, this.state.selected_options.category) 
	const subcategory_value = this.getOption(this.state.subcategories, this.state.selected_options.subcategories) 
	const locations_value = this.getOption(this.state.locations, this.state.selected_options.areas) 
	return (
		<div className={`search-form-wrapper ${this.props.show ? 'open' : ''}`}>
			<div className='search-container' onClick={() => this.props.setShow(false)}>
				
			</div>
			<div className='search-component'>
				<FontAwesomeIcon className='remove-icon' icon={faRemove} onClick={() => this.props.setShow(false)} />
				<div className='row pt-4'>
					<div className='col-md-12'>
						<label className="filter-title">{i18n.t('search_purpose')}</label>
						<div className="radio-group radio-group-light">
							<div className="radio">
								<label className="check-label d-block">
									<input type="radio" value="1" 
												checked={parseInt(this.state.selected_options.for) === 1} 
												onChange={(e) => this.setSelectedValue('for', e.target.value)} />
									<span className="radio-choice">{i18n.t('search_for_sell')}</span>
								</label>
							</div>
							<div className="radio">
								<label className="check-label d-block">
									<input type="radio" value="2" 
												checked={parseInt(this.state.selected_options.for) === 2} 
												onChange={(e) => this.setSelectedValue('for', e.target.value)} />
									<span className="radio-choice">{i18n.t('search_for_rent')}</span>
								</label>
							</div>
						</div>
					</div>
					{/* <div className='col-md-12'>
						<label className="filter-title">{i18n.t('search_locations')}</label>
						
						<AsyncSelect
							className="select-element"
							classNamePrefix="select"
							value={locations_value}
							// defaultValue={locations_value}
							placeholder={i18n.t('type_a_location')}
							name="areas"
							id="areas"
							loadOptions={this.locationSearch}
							defaultOptions
							cacheOptions
							onChange={(e) => this.setLocation(e)}
							isMulti
						/>
							
					</div> */}
				</div>
				<div className='row'>
					<div className='col-md-12'>
						<label className="filter-title">{i18n.t('search_category')}</label>
						{
							(categories && categories.length > 0) &&
							<Select
								className="select-element"
								classNamePrefix="select"
								defaultValue={categories[0]}
								placeholder={i18n.t('choose_category')}
								value={category_value}
								isSearchable={true}
								name="categories"
								options={categories}
								onChange={(e) => this.setSelectedCategory(e.value)}
							/>
						}
					</div>
					<div className='col-md-12'>
						<label className="filter-title">{i18n.t('search_subcategory')}</label>
						{
							(subcategories) &&
							<Select
								className="select-element"
								classNamePrefix="select"
								value={subcategory_value}
								placeholder={i18n.t('choose_subcategory')}
								isSearchable={true}
								name="subcategories"
								options={subcategories}
								onChange={(e) => this.setSelectedValue('subcategories', e.map(a => a.value))}
								isMulti
							/>
						}
					</div>
				</div>
				<div className='row'>
					<div className='col-md-6'>
						<label className="filter-title">{i18n.t('search_price_from')}</label>
						<input 
							className='form-control-light' 
							type='number' 
							id="price-from-input"
							value={this.state.selected_options.priceFrom || ''} 
							onChange={(e) => this.setSelectedValue('priceFrom', e.target.value)}
						/>
					</div>
					<div className='col-md-6'>
						<label className="filter-title">{i18n.t('search_price_to')}</label>
						<input 
							className='form-control-light' 
							type='number' 
							value={this.state.selected_options.priceTo || ''} 
							onChange={(e) => this.setSelectedValue('priceTo', e.target.value)}
						/>
					</div>
				</div>
				<div className='row'>
					<div className='col-md-6'>
						<label className="filter-title">{i18n.t('search_sq_from')}</label>
						<input 
							className='form-control-light' 
							type='number' 
							value={this.state.selected_options.areaFrom || ''} 
							onChange={(e) => this.setSelectedValue('areaFrom', e.target.value)}
						/>
					</div>
					<div className='col-md-6'>
						<label className="filter-title">{i18n.t('search_sq_to')}</label>
						<input 
							className='form-control-light' 
							type='number' 
							value={this.state.selected_options.areaTo || ''} 
							onChange={(e) => this.setSelectedValue('areaTo', e.target.value)}
						/>
					</div>
				</div>
			</div>
		</div>
	)
  }
}

export default SearchProperties;