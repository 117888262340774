import { createBrowserHistory, } from 'history';
import config from '../config';

const history = createBrowserHistory({ basename: config.basename });

history.customChangeEvent = () => {}

history.setCustomChangeEvent = (fn) => {
  history.customChangeEvent = fn
}

history.listen((e) => {
  window.scroll(0, 0);
  history.last = e.pathname;
  history.customChangeEvent()
});

export default history;