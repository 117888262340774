import React, { Component , Fragment } from "react";
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import Rightchat from "../components/Rightchat";
import Select from 'react-select';
import request from "../request";
import i18n from "../i18n";
import * as L from "leaflet";
import Utils from "../utils";
import Swal from "sweetalert2";
import history from '../helpers/history';
import storage from "../storage";

class PropertyForm extends Component {

  constructor(props) {
    super(props);

    this.categoryRef = React.createRef(null)
    this.mapRef = React.createRef(null)

    this.required_fields = [
      'int_to',
      'cat_basic_id',
      'cat_id',
      'price',
      'sq',
      'lat',
      'lng',
      'zip',
      'description',
      'description_en'
    ]

    this.error_messages = {
      'int_to': i18n.t('please_choose_transaction'),
      'cat_basic_id': i18n.t('please_choose_basic_category'),
      'cat_id': i18n.t('please_choose_category'),
      'price': i18n.t('please_set_price'),
      'sq': i18n.t('please_set_sq'),
      'lat': i18n.t('please_set_cordes'),
      'lng': i18n.t('please_set_cordes'),
      'zip': i18n.t('please_set_zip'),
      'description': i18n.t('please_set_description'),
      'description_en': i18n.t('please_set_description_en'),
    }
    /**
     * basic-info-fields
        location-fields
        optional-fields
     */
    this.state = {
      address_suggestions: [],
      fields_to_show: 'basic-info-fields',
      form_data: {
        cat_basic_id: 1,
        description: '',
        description_en: '',
      },
      publish: {
        profile: 0,
        networks: []
      },
      lookups: {
        transaction_type: [],
        categories_basic: [],
        categories: [],
        nomoi: [],
        regions: [],
        locations: [],
        zoning: [],
        floors: [],
        heat: [],
        energy_class: [],
        views: [],
        frames: [],
        floors_materials: [],
        closets: [],
        doors: [],
        garden: [],
        pool: [],
        access_type: [],
        conditions: [],
        directions: [],
        orientations: [],
        public_services: [],
        current_type: [],
        land_use: [],
        storing: false
      },
      networks: []
    };

	  this.getCurrentValue = this.getCurrentValue.bind(this) 
	  this.getOptionValue = this.getOptionValue.bind(this) 
  }

  checkRequirements() {
    let passed = true

    this.required_fields.forEach(field => {
      if (!this.state.form_data[field] || this.state.form_data[field] === '') {
        passed = false
      }
    })

    return passed
  }
  getNextRequired() {
    let passed = null
    
    this.required_fields.forEach(field => {
      if (!this.state.form_data[field] || this.state.form_data[field] === '') {
        passed = field
        return
      }
    })

    return passed
  }

  storeProperty() {
    if (!this.state.storing) {
      this.setState({
        storing: true
      })

      if (!this.checkRequirements()) {
        Swal.fire({
          title: i18n.t('something_went_wrong'),
          text: this.error_messages[this.getNextRequired()],
          icon: 'error'
        })
        this.setState({
          storing: false
        })
        return
      }
      if (this.state.form_data.id && this.state.form_data.id > 0) {

        request.post(`properties/update`, {
          ...this.state.form_data,
          publish_to: this.state.publish
        }).then(response => {
          if (response.status === 200) {
            Swal.fire({
              title: i18n.t('property_updated'),
              icon: 'success'
            }).then(() => {
              this.setState({
                storing: false
              })
              // history.push(`/edit_property_media/${response.data.id}`)
            })
          }
          else {
            this.setState({
              storing: false
            })
          }
        })
      }
      else {
        request.post(`properties/store`, {
          ...this.state.form_data,
          publish_to: this.state.publish
        }).then(response => {
          if (response.status === 200) {
            Swal.fire({
              title: i18n.t('property_inserted'),
              icon: 'success'
            }).then(() => {
              history.push(`/edit_property_media/${response.data.id}`)
            })
          }
          else {
            this.setState({
              storing: false
            })
          }
        })
      }
    }
    
    
  }

  componentDidMount() {
    const id = this.props.match.params.id

    if (id) {
      request.get(`property_for_edit/${id}`, this.state.form_data).then(response => {
        this.setState({
          form_data: {
            ...response.data,
            id: id
          }
        }, () => {
          this.getNetworks()
          this.initMap()
          this.showProperFieldsForCategory(this.state.form_data.cat_basic_id)
        })
      })
    }
    else {
      this.getNetworks()
      this.initMap()
      this.showProperFieldsForCategory(this.state.form_data.cat_basic_id)
    }

    storage.onChange(() => {
      this.getNetworks()
    })
    
    request.get('form_lookups').then(response => {
      
      var lookups = [] 
      Object.keys(response.data).map((index, obj) => {
        lookups[index] = response.data[index].map(lookup => {
          return {
            label: i18n.get_data('name', lookup),
            value: lookup.id,
            parent: lookup.parent ? lookup.parent : null
          }
        })
        
      })
      
      this.setState({
        lookups: lookups
      }, () => {
        this.setState({
          lookups: {
            ...this.state.lookups,
            categories: this.setShowSubcategories(this.state.form_data.cat_basic_id)
          }
        })
      })
    })

    
    
  }

  getNetworks = () => {
    request.get(`networks/members/${storage.me ? storage.me.realtors_id : ''}`).then(response => {
      if (response.status === 200) {
        
          this.setState({
            networks: response.data.map(a => {
              return {
                name: a.name,
                id: a.id,
                published: false
              }
            }),
            publish: {
              profile: this.state.form_data.published ? parseInt(this.state.form_data.published) : 0,
              networks: this.state.form_data.networks ? this.state.form_data.networks : []
            }
          })
        
        
      }
    });
  }

  initMap = () => {
    // origin style https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png
    let tileLayer = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png',{
      attribution: 'Real Status Network',
    });

    this.map = L.map(this.mapRef.current, {
      layers: [tileLayer],
      maxZoom: 19,
      minZoom: 5,
      gestureHandling: true
    });
    const map = this.map;

    map.setView([38.227322, 23.211220], 7);

    this.customMarkerIcon = L.icon({
      iconUrl: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAApCAYAAADAk4LOAAAFgUlEQVR4Aa1XA5BjWRTN2oW17d3YaZtr2962HUzbDNpjszW24mRt28p47v7zq/bXZtrp/lWnXr337j3nPCe85NcypgSFdugCpW5YoDAMRaIMqRi6aKq5E3YqDQO3qAwjVWrD8Ncq/RBpykd8oZUb/kaJutow8r1aP9II0WmLKLIsJyv1w/kqw9Ch2MYdB++12Onxee/QMwvf4/Dk/Lfp/i4nxTXtOoQ4pW5Aj7wpici1A9erdAN2OH64x8OSP9j3Ft3b7aWkTg/Fm91siTra0f9on5sQr9INejH6CUUUpavjFNq1B+Oadhxmnfa8RfEmN8VNAsQhPqF55xHkMzz3jSmChWU6f7/XZKNH+9+hBLOHYozuKQPxyMPUKkrX/K0uWnfFaJGS1QPRtZsOPtr3NsW0uyh6NNCOkU3Yz+bXbT3I8G3xE5EXLXtCXbbqwCO9zPQYPRTZ5vIDXD7U+w7rFDEoUUf7ibHIR4y6bLVPXrz8JVZEql13trxwue/uDivd3fkWRbS6/IA2bID4uk0UpF1N8qLlbBlXs4Ee7HLTfV1j54APvODnSfOWBqtKVvjgLKzF5YdEk5ewRkGlK0i33Eofffc7HT56jD7/6U+qH3Cx7SBLNntH5YIPvODnyfIXZYRVDPqgHtLs5ABHD3YzLuespb7t79FY34DjMwrVrcTuwlT55YMPvOBnRrJ4VXTdNnYug5ucHLBjEpt30701A3Ts+HEa73u6dT3FNWwflY86eMHPk+Yu+i6pzUpRrW7SNDg5JHR4KapmM5Wv2E8Tfcb1HoqqHMHU+uWDD7zg54mz5/2BSnizi9T1Dg4QQXLToGNCkb6tb1NU+QAlGr1++eADrzhn/u8Q2YZhQVlZ5+CAOtqfbhmaUCS1ezNFVm2imDbPmPng5wmz+gwh+oHDce0eUtQ6OGDIyR0uUhUsoO3vfDmmgOezH0mZN59x7MBi++WDL1g/eEiU3avlidO671bkLfwbw5XV2P8Pzo0ydy4t2/0eu33xYSOMOD8hTf4CrBtGMSoXfPLchX+J0ruSePw3LZeK0juPJbYzrhkH0io7B3k164hiGvawhOKMLkrQLyVpZg8rHFW7E2uHOL888IBPlNZ1FPzstSJM694fWr6RwpvcJK60+0HCILTBzZLFNdtAzJaohze60T8qBzyh5ZuOg5e7uwQppofEmf2++DYvmySqGBuKaicF1blQjhuHdvCIMvp8whTTfZzI7RldpwtSzL+F1+wkdZ2TBOW2gIF88PBTzD/gpeREAMEbxnJcaJHNHrpzji0gQCS6hdkEeYt9DF/2qPcEC8RM28Hwmr3sdNyht00byAut2k3gufWNtgtOEOFGUwcXWNDbdNbpgBGxEvKkOQsxivJx33iow0Vw5S6SVTrpVq11ysA2Rp7gTfPfktc6zhtXBBC+adRLshf6sG2RfHPZ5EAc4sVZ83yCN00Fk/4kggu40ZTvIEm5g24qtU4KjBrx/BTTH8ifVASAG7gKrnWxJDcU7x8X6Ecczhm3o6YicvsLXWfh3Ch1W0k8x0nXF+0fFxgt4phz8QvypiwCCFKMqXCnqXExjq10beH+UUA7+nG6mdG/Pu0f3LgFcGrl2s0kNNjpmoJ9o4B29CMO8dMT4Q5ox8uitF6fqsrJOr8qnwNbRzv6hSnG5wP+64C7h9lp30hKNtKdWjtdkbuPA19nJ7Tz3zR/ibgARbhb4AlhavcBebmTHcFl2fvYEnW0ox9xMxKBS8btJ+KiEbq9zA4RthQXDhPa0T9TEe69gWupwc6uBUphquXgf+/FrIjweHQS4/pduMe5ERUMHUd9xv8ZR98CxkS4F2n3EUrUZ10EYNw7BWm9x1GiPssi3GgiGRDKWRYZfXlON+dfNbM+GgIwYdwAAAAASUVORK5CYII=',
      iconSize: [25, 41],
      iconAnchor: [12.5, 41]
    });

    let latlng = [38.227322, 23.211220]

    if (this.state.form_data.lat && this.state.form_data.lng) {
      latlng = [this.state.form_data.lat, this.state.form_data.lng]
    }
    this.marker = L.marker(latlng, { 
      icon: this.customMarkerIcon, 
      draggable: true,
      autoPan: true
    });

    map.panTo(new L.LatLng(latlng[0], latlng[1]))

    this.marker.on('dragend', (e) => {
      const cordes = this.marker.getLatLng()

      Utils.reverseGeocode(cordes.lat, cordes.lng).then(response => {
        if (response.status === 200) {
          if (response.data.address) {
            const address_text = response.data.address.Match_addr !== '' ? response.data.address.Match_addr : ''
            this.setState({
              form_data: {
                ...this.state.form_data,
                lat: cordes.lat,
                lng: cordes.lng,
                address: address_text,
                zip: response.data.address.Postal
              }
            })
          }
          else {
            this.setState({
              form_data: {
                ...this.state.form_data,
                lat: cordes.lat,
                lng: cordes.lng,
              }
            })
          }
        }
      })
      
  });

    map.addLayer(this.marker);
  }

  showProperFieldsForCategory(categ_id) {
    document.querySelectorAll('.residential, .proffesional, .land, .parking').forEach(function(el) {
      el.style.display = 'none';
    });
    categ_id = parseInt(categ_id)
    let categ_to_show = ''
    if (categ_id === 1) {
      categ_to_show = 'residential'
    }
    else if (categ_id === 2) {
      categ_to_show = 'proffesional'
    }
    else if (categ_id === 3) {
      categ_to_show = 'land'
    }
    else if (categ_id === 4) {
      categ_to_show = 'parking'
    } 
    document.querySelectorAll(`.${categ_to_show}`).forEach(function(el) {
      if (el.nodeName === 'LABEL') {
        el.style.display = 'inline-block';
      }
      else {
        el.style.display = 'block';
      }
      
    });
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevState.form_data.cat_basic_id !== this.state.form_data.cat_basic_id) {

  //   }
  // }

  setValue(key, value) {
    this.setState({
      form_data: {
        ...this.state.form_data,
        [key]: value
      }
    })
  }

  getCurrentValue(key, is_bool = false) {
    if (is_bool) {
      return this.state.form_data[key] && (this.state.form_data[key] === "1" || this.state.form_data[key] === true)
    }
	  return this.state.form_data[key] ? this.state.form_data[key] : ''
  }

  getOptionValue(key, lookup) {
    const value = this.getCurrentValue(key)
    if (value !== '' && this.state.lookups[lookup]) {
      return this.state.lookups[lookup].filter(l => parseInt(l.value) === parseInt(value))[0]
    }

    return ''
  }

  setShowSubcategories(cat_id) {
    return this.state.lookups.categories.map(c => {
      const show =  parseInt(c.parent) === parseInt(cat_id)
      return {
        ...c,
        show: show
      }
    })

  }
  setCategory(val) {
    const categories = this.setShowSubcategories(val)

    this.setState({
      form_data: {
        ...this.state.form_data,
        cat_basic_id: val,
        cat_id: ''
      },
      lookups: {
        ...this.state.lookups,
        categories: categories
      }
    }, () => {
      this.showProperFieldsForCategory(this.state.form_data.cat_basic_id)
    })
  }

  setCheckValue = (key, check) => {
    this.setState({
      form_data: {
        ...this.state.form_data,
        [key]: check.target.checked
      }
    })
  }

  searchAddress(searchText) {
    if (searchText && searchText.length >= 3) {
			if (this.addressTimeout) window.clearTimeout(this.addressTimeout)
			this.addressTimeout = setTimeout(() => {
				Utils.searchAddress(searchText).then(response => {
          if (response.status === 200) {
            

            this.setState({
              address_suggestions: response.data.map(place => {
                const address = place.address
                const zip = address.postcode ? address.postcode : ''
                const road = address.road ? address.road : ''
                const house_number = address.house_number ? ' ' + address.house_number : ''
                const city = address.city ? address.city : address.neighbourhood ? address.neighbourhood : address.municipality ? address.municipality : ''
      
                const address_text = road !== '' ? `${road}${house_number}, ${city}` : city

                return {
                  address: address_text,
                  zip: zip,
                  lat: place.lat,
                  lng: place.lon
                }

              })
            })
          }
				})
			}, 1000)
    }
  }

  setCustomAddress(s) {
    var lat = (s.lat);
    var lng = (s.lng);
    var newLatLng = new L.LatLng(lat, lng);
    this.marker.setLatLng(newLatLng); 
    this.map.flyTo([lat, lng], 16);
    // this.map.setZoom(13);

    this.setState({
      address_suggestions: [],
      form_data: {
        ...this.state.form_data,
        zip: s.zip,
        lat: s.lat, 
        lng: s.lng,
        address: s.address
      }
    })
  }

  goTo(fields) {
    this.setState({
      fields_to_show: fields
    }, () => {
      this.map.invalidateSize()
    })
  }

  setPublishedNetworks = (checked, network_id) => {
    if (!checked) {
      this.setState({
        publish: {
          ...this.state.publish,
          networks: this.state.publish.networks.filter(n => parseInt(n) !== parseInt(network_id))
        }
      })
    }
    else {
      this.setState({
        publish: {
          ...this.state.publish,
          networks: this.state.publish.networks.concat([network_id])
        }
      })
    }
  } 

  render() {
	  const lookups = this.state.lookups
  
    return (
      <Fragment> 
        <Header />
        <Leftnav />
          <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom p-0">
              <div className="middle-sidebar-left pe-0" style={{maxWidth: "100%"}}>
                <div className="container">
                  <div className="row sticky-top form-header">
                    <div className="col-md-12">
                      {
                        this.state.form_data &&
                        <div>
                          {
                            this.state.form_data.id ?
                            <h3>{i18n.t('update_property')} #{this.state.form_data.id}</h3> :
                            <h3>{i18n.t('insert_property')}</h3>
                          }
                        </div>
                      }
                      <div className="property-form-steps">
                        <div onClick={() => this.goTo('basic-info-fields')} className={`property-form-step ${this.state.fields_to_show === 'basic-info-fields' ? 'active' : ''}`}>
                          <span className="step">1. {i18n.t('property_basic_information')}</span>
                        </div>
                        <div onClick={() => this.goTo('location-fields')} className={`property-form-step ${this.state.fields_to_show === 'location-fields' ? 'active' : ''}`}>
                          <span className="step">2. {i18n.t('property_location_information')}</span>
                        </div>
                        <div onClick={() => this.goTo('optional-fields')} className={`property-form-step ${this.state.fields_to_show === 'optional-fields' ? 'active' : ''}`}>
                          <span className="step">3. {i18n.t('property_optional')}</span>
                        </div>
                        <div onClick={() => this.goTo('publish-networks')} className={`property-form-step ${this.state.fields_to_show === 'publish-networks' ? 'active' : ''}`}>
                          <span className="step">4. {i18n.t('publish')}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  { /**property_type */}
                  <div className={`row basic-info-fields ${this.state.fields_to_show === 'basic-info-fields' ? 'active' : 'hidden'}`}>
                    <div className="col-md-12">
                      <h4 className="property-form-title">{i18n.t('property_type')}</h4>
                    </div>
                    <div className="col-md-4">
                      <label>{i18n.t('purpose')}</label>
                      <Select 
                        className="select-element"
                        classNamePrefix="select"
                        placeholder={i18n.t('choose_purpose')}
                        value={this.getOptionValue('int_to', 'transaction_type')}
                        isSearchable={false}
                        name="transaction_type"
                        options={lookups.transaction_type}
                        onChange={(e) => this.setValue('int_to', e.value)}
                      />
                    </div>
                    <div className="col-md-4">
                      <label>{i18n.t('category')}</label>
                      <Select 
                        ref={this.categoryRef}
                        className="select-element"
                        classNamePrefix="select"
                        placeholder={i18n.t('choose_category')}
                        value={this.getOptionValue('cat_basic_id', 'categories_basic')}
                        isSearchable={false}
                        name="categories_basic"
                        options={lookups.categories_basic}
                        onChange={(e) => this.setCategory(e.value)}
                      />
                    </div>
                    <div className="col-md-4">
                      <label>{i18n.t('subcategory')}</label>
                      <Select 
                        className="select-element"
                        classNamePrefix="select"
                        placeholder={i18n.t('choose_subcategory')}
                        value={this.getOptionValue('cat_id', 'categories')}
                        isSearchable={false}
                        name="categories"
                        options={lookups.categories.filter(c => c.show)}
                        onChange={(e) => this.setValue('cat_id', e.value)}
                      />
                    </div>
                  </div>
                  { /**property_values */}
                  <div className={`row basic-info-fields mt-4 ${this.state.fields_to_show === 'basic-info-fields' ? 'active' : 'hidden'}`}>
                    <div className="col-md-12">
                      <h4 className="property-form-title">{i18n.t('property_values')}</h4>
                    </div>
                    <div className="col-md-4">
                      <label>{i18n.t('zone')}</label>
                      <Select 
                        className="select-element"
                        classNamePrefix="select"
                        placeholder={i18n.t('choose_zone')}
                        value={this.getOptionValue('zoning_id', 'zoning')}
                        isSearchable={false}
                        name="zoning"
                        options={lookups.zoning}
                        onChange={(e) => this.setValue('zoning_id', e.value)}
                      />
                    </div>
                    <div className="col-md-4">
                      <label>{i18n.t('set_price')}</label>
                      <input className="form-control-light" type="number" value={this.getCurrentValue('price')} onChange={(e) => this.setValue('price', e.target.value)} placeholder={i18n.t('set_price')}/>
                    </div>
                    <div className="col-md-4">
                      <label>{i18n.t('set_sq')}</label>
                      <input className="form-control-light" type="number" value={this.getCurrentValue('sq')} onChange={(e) => this.setValue('sq', e.target.value)} placeholder={i18n.t('set_sq')}/>
                    </div>
                  </div>
                  { /**property_additional */}
                  <div className={`row basic-info-fields mt-4 ${this.state.fields_to_show === 'basic-info-fields' ? 'active' : 'hidden'}`}>
                    <div className="col-md-12">
                      <h4 className="property-form-title">{i18n.t('property_additional')}</h4>
                    </div>
                    <div className="col-md-3 residential proffesional">
                      <label className="residential">{i18n.t('set_rooms')}</label>
                      <label className="proffesional">{i18n.t('set_spaces')}</label>
                      <input className="form-control-light" type="number" value={this.getCurrentValue('rooms')} onChange={(e) => this.setValue('rooms', e.target.value)} placeholder={i18n.t('rooms')}/>
                    </div>
                    <div className="col-md-3 residential proffesional">
                      <label>{i18n.t('set_livingrooms')}</label>
                      <input className="form-control-light" type="number" value={this.getCurrentValue('livingroom')} onChange={(e) => this.setValue('livingroom', e.target.value)} placeholder={i18n.t('livingrooms')}/>
                    </div>
                    <div className="col-md-3 residential proffesional">
                      <label>{i18n.t('set_bathrooms')}</label>
                      <input className="form-control-light" type="number" value={this.getCurrentValue('bathroom')} onChange={(e) => this.setValue('bathroom', e.target.value)} placeholder={i18n.t('bathrooms')}/>
                    </div>
                    <div className="col-md-3 residential proffesional">
                      <label>{i18n.t('set_wc')}</label>
                      <input className="form-control-light" type="number" value={this.getCurrentValue('wc')} onChange={(e) => this.setValue('wc', e.target.value)} placeholder={i18n.t('wc')}/>
                    </div>
                    
                    <div className="col-md-3 land">
                      <label>{i18n.t('set_structure_factor')}</label>
                      <input className="form-control-light" type="number" value={this.getCurrentValue('sdom')} onChange={(e) => this.setValue('sdom', e.target.value)} placeholder={i18n.t('structure_factor')}/>
                    </div>
                    <div className="col-md-3 land">
                      <label>{i18n.t('set_coverage_factor')}</label>
                      <input className="form-control-light" type="number" value={this.getCurrentValue('skal')} onChange={(e) => this.setValue('skal', e.target.value)} placeholder={i18n.t('coverage_factor')}/>
                    </div>
                    <div className="col-md-3 land">
                      <label>{i18n.t('set_ipsos')}</label>
                      <input className="form-control-light" type="number" value={this.getCurrentValue('ipsos')} onChange={(e) => this.setValue('ipsos', e.target.value)} placeholder={i18n.t('ipsos')}/>
                    </div>
                    <div className="col-md-3 land">
                      <label>{i18n.t('set_sxedio')}</label>
                      <Select 
                        className="select-element"
                        classNamePrefix="select"
                        placeholder={i18n.t('choose_sxedio')}
                        value={this.state.form_data.sxedio === 1 ? {
                          label: i18n.t('in_plan'),
                          value: 1
                        } :
                        {
                          label: i18n.t('not_in_plan'),
                          value: 2
                        }
                        }
                        isSearchable={false}
                        name="sxedio"
                        options={[
                          {
                            label: i18n.t('in_plan'),
                            value: 1
                          },
                          {
                            label: i18n.t('not_in_plan'),
                            value: 2
                          }
                        ]}
                        onChange={(e) => this.setValue('sxedio', e.value)}
                      />
                    </div>
                  </div>
                  { /**property_descriptions */}
                  <div className={`row basic-info-fields mt-4 ${this.state.fields_to_show === 'basic-info-fields' ? 'active' : 'hidden'}`}>
                    <div className="col-md-12">
                      <h4 className="property-form-title">{i18n.t('property_descriptions')}</h4>
                    </div>
                    <div className="col-md-12">
                      <label>{i18n.t('set_description')}</label>
                      <textarea 
                        className="form-control-light mh-150"
                        value={this.getCurrentValue('description')}
                        onChange={(e) => this.setValue('description', e.target.value)}
                        placeholder={i18n.t('set_description')}
                      />
                      {/* <input className="form-control-light" type="text" value={this.getCurrentValue('description')} onChange={(e) => this.setValue('description', e.target.value)} placeholder={i18n.t('set_description')}/> */}
                      <span className="remaining-chars">{1000 - this.getCurrentValue('description').length} / 1000</span>
                    </div>
                    <div className="col-md-12">
                      <label>{i18n.t('set_description')}</label>
                      <textarea 
                        className="form-control-light mh-150"
                        value={this.getCurrentValue('description_en')}
                        onChange={(e) => this.setValue('description_en', e.target.value)}
                        placeholder={i18n.t('set_description_en')}
                      />
                      {/* <input className="form-control-light" type="text" value={this.getCurrentValue('description_en')} onChange={(e) => this.setValue('description_en', e.target.value)} placeholder={i18n.t('set_description_en')}/> */}
                      <span className="remaining-chars">{1000 - this.getCurrentValue('description_en').length} / 1000</span>
                    </div>
                  </div>
                  <div className={`row basic-info-fields mt-4 ${this.state.fields_to_show === 'basic-info-fields' ? 'active' : 'hidden'}`}>
                    
                    <div className="col-md-3 offset-md-9">
                      <button className="btn btn-primary text-white" onClick={() => this.goTo('location-fields')}>{i18n.t('next_step')}</button>
                    </div>
                  </div>
                  { /**property_location */}
                  <div className={`row location-fields mt-4 ${this.state.fields_to_show === 'location-fields' ? 'active' : 'hidden'}`}>
                    <div className="col-md-12">
                      <h4 className="property-form-title">{i18n.t('property_location')}</h4>
                    </div>
                    <div className="col-md-12">
                      <div className="map-wrapper" id="map" ref={this.mapRef}></div>
                    </div>
                    <div className="col-md-8">
                      <label>{i18n.t('set_address')}</label>
                      <input className="form-control-light" type="text" value={this.getCurrentValue('address')} onChange={(e) => {
                        this.setValue('address', e.target.value)
                        this.searchAddress(e.target.value)
                      }} placeholder={i18n.t('set_address')}/>
                      <div className="address-suggestions">
                        {
                          this.state.address_suggestions.map(s => {
                            return <span onClick={() => this.setCustomAddress(s)}>{s.address}</span>
                          })
                        }
                      </div>
                    </div>
                    <div className="col-md-4">
                      <label>{i18n.t('set_zip')}</label>
                      <input className="form-control-light" type="text" value={this.getCurrentValue('zip')} onChange={(e) => this.setValue('zip', e.target.value)} placeholder={i18n.t('set_zip')}/>
                    </div>
                  </div>
                  { /**property_distances */}
                  <div className={`row location-fields mt-4 ${this.state.fields_to_show === 'location-fields' ? 'active' : 'hidden'}`}>
                    <div className="col-md-12">
                      <h4 className="property-form-title">{i18n.t('property_distances')}</h4>
                    </div>
                    <div className="col-md-3 residential proffesional land">
                      <label>{i18n.t('sea_distance')}</label>
                      <input className="form-control-light" type="number" value={this.getCurrentValue('d_sea')} onChange={(e) => this.setValue('d_sea', e.target.value)} placeholder={i18n.t('set_sea_distance')}/>
                    </div>
                    <div className="col-md-3 residential proffesional land">
                      <label>{i18n.t('city_distance')}</label>
                      <input className="form-control-light" type="number" value={this.getCurrentValue('d_city')} onChange={(e) => this.setValue('d_city', e.target.value)} placeholder={i18n.t('set_city_distance')}/>
                    </div>
                    <div className="col-md-3 residential proffesional land">
                      <label>{i18n.t('village_distance')}</label>
                      <input className="form-control-light" type="number" value={this.getCurrentValue('d_village')} onChange={(e) => this.setValue('d_village', e.target.value)} placeholder={i18n.t('set_village_distance')}/>
                    </div>
                    <div className="col-md-3 residential proffesional land">
                      <label>{i18n.t('airport_distance')}</label>
                      <input className="form-control-light" type="number" value={this.getCurrentValue('d_airport')} onChange={(e) => this.setValue('d_airport', e.target.value)} placeholder={i18n.t('set_airport_distance')}/>
                    </div>
                  </div>
                  <div className={`row location-fields mt-4 ${this.state.fields_to_show === 'location-fields' ? 'active' : 'hidden'}`}>
                    <div className="col-md-3 my-4">
                      <button className="btn btn-primary text-white" onClick={() => this.goTo('basic-info-fields')}>{i18n.t('previous_step')}</button>
                    </div>
                    <div className="col-md-3 my-4 offset-md-6">
                      <button className="btn btn-primary text-white" onClick={() => this.goTo('optional-fields')}>{i18n.t('next_step')}</button>
                    </div>
                  </div>
                  { /**property_optional */}
                  <div className={`row optional-fields mt-4 ${this.state.fields_to_show === 'optional-fields' ? 'active' : 'hidden'}`}>
                    <div className="col-md-12">
                      <h4 className="property-form-title">{i18n.t('property_optional')}</h4>
                    </div>
                    <div className="col-md-4">
                      <label>{i18n.t('view')}</label>
                      <Select 
                        className="select-element"
                        classNamePrefix="select"
                        placeholder={i18n.t('choose_view')}
                        value={this.getOptionValue('view', 'views')}
                        isSearchable={false}
                        name="zoning"
                        options={lookups.views}
                        onChange={(e) => this.setValue('view', e.value)}
                      />
                    </div>
                    <div className="col-md-4 residential proffesional">
                      <label>{i18n.t('frame')}</label>
                      <Select 
                        className="select-element"
                        classNamePrefix="select"
                        placeholder={i18n.t('choose_frame')}
                        value={this.getOptionValue('kouf', 'frames')}
                        isSearchable={false}
                        name="zoning"
                        options={lookups.frames}
                        onChange={(e) => this.setValue('kouf', e.value)}
                      />
                    </div>
                    <div className="col-md-4 residential proffesional">
                      <label>{i18n.t('choose_floors')}</label>
                      <Select 
                        className="select-element"
                        classNamePrefix="select"
                        placeholder={i18n.t('choose_floors')}
                        value={this.getOptionValue('floor', 'floors')}
                        isSearchable={false}
                        name="zoning"
                        options={lookups.floors}
                        onChange={(e) => this.setValue('floor', e.value)}
                      />
                    </div>
                    <div className="col-md-4 residential proffesional">
                      <label>{i18n.t('choose_heat')}</label>
                      <Select 
                        className="select-element"
                        classNamePrefix="select"
                        placeholder={i18n.t('choose_heat')}
                        value={this.getOptionValue('heat_id', 'heat')}
                        isSearchable={false}
                        name="zoning"
                        options={lookups.heat}
                        onChange={(e) => this.setValue('heat_id', e.value)}
                      />
                    </div>
                    <div className="col-md-4 residential proffesional">
                      <label>{i18n.t('choose_energy_class')}</label>
                      <Select 
                        className="select-element"
                        classNamePrefix="select"
                        placeholder={i18n.t('choose_energy_class')}
                        value={this.getOptionValue('energy_class', 'energy_class')}
                        isSearchable={false}
                        name="zoning"
                        options={lookups.energy_class}
                        onChange={(e) => this.setValue('energy_class', e.value)}
                      />
                    </div>
                    <div className="col-md-4 residential proffesional">
                      <label>{i18n.t('floors_materials')}</label>
                      <Select 
                        className="select-element"
                        classNamePrefix="select"
                        placeholder={i18n.t('choose_floors_materials')}
                        value={this.getOptionValue('floors_mat', 'floors_materials')}
                        isSearchable={false}
                        name="zoning"
                        options={lookups.floors_materials}
                        onChange={(e) => this.setValue('floors_mat', e.value)}
                      />
                    </div>
                    <div className="col-md-4 residential proffesional">
                      <label>{i18n.t('door')}</label>
                      <Select 
                        className="select-element"
                        classNamePrefix="select"
                        placeholder={i18n.t('choose_door')}
                        value={this.getOptionValue('tdoor', 'doors')}
                        isSearchable={false}
                        name="zoning"
                        options={lookups.doors}
                        onChange={(e) => this.setValue('tdoor', e.value)}
                      />
                    </div>
                    <div className="col-md-4 residential proffesional">
                      <label>{i18n.t('closet')}</label>
                      <Select 
                        className="select-element"
                        classNamePrefix="select"
                        placeholder={i18n.t('choose_closet')}
                        value={this.getOptionValue('closet', 'closets')}
                        isSearchable={false}
                        name="zoning"
                        options={lookups.closets}
                        onChange={(e) => this.setValue('closet', e.value)}
                      />
                    </div>
                    <div className="col-md-4 residential proffesional">
                      <label>{i18n.t('garden')}</label>
                      <Select 
                        className="select-element"
                        classNamePrefix="select"
                        placeholder={i18n.t('choose_garden')}
                        value={this.getOptionValue('garden', 'garden')}
                        isSearchable={false}
                        name="zoning"
                        options={lookups.garden}
                        onChange={(e) => this.setValue('garden', e.value)}
                      />
                    </div>
                    <div className="col-md-4 residential proffesional">
                      <label>{i18n.t('pool')}</label>
                      <Select 
                        className="select-element"
                        classNamePrefix="select"
                        placeholder={i18n.t('choose_pool')}
                        value={this.getOptionValue('pool', 'pool')}
                        isSearchable={false}
                        name="zoning"
                        options={lookups.pool}
                        onChange={(e) => this.setValue('pool', e.value)}
                      />
                    </div>
                    <div className="col-md-4">
                      <label>{i18n.t('access_type')}</label>
                      <Select 
                        className="select-element"
                        classNamePrefix="select"
                        placeholder={i18n.t('choose_access_type')}
                        value={this.getOptionValue('access', 'access_type')}
                        isSearchable={false}
                        name="zoning"
                        options={lookups.access_type}
                        onChange={(e) => this.setValue('access', e.value)}
                      />
                    </div>
                    <div className="col-md-4 land">
                      <label>{i18n.t('land_use')}</label>
                      <Select 
                        className="select-element"
                        classNamePrefix="select"
                        placeholder={i18n.t('choose_land_use')}
                        value={this.getOptionValue('land_use', 'land_use')}
                        isSearchable={false}
                        name="zoning"
                        options={lookups.land_use}
                        onChange={(e) => this.setValue('land_use', e.value)}
                      />
                    </div>
                    <div className="col-md-4 land">
                      <label>{i18n.t('land_slope')}</label>
                      <Select 
                        className="select-element"
                        classNamePrefix="select"
                        placeholder={i18n.t('choose_land_slope')}
                        value={this.getOptionValue('land_slope', 'land_slope')}
                        isSearchable={false}
                        name="zoning"
                        options={lookups.land_slope}
                        onChange={(e) => this.setValue('land_slope', e.value)}
                      />
                    </div>
                    <div className="col-md-4 residential proffesional">
                      <label>{i18n.t('parking')}</label>
                      <Select 
                        className="select-element"
                        classNamePrefix="select"
                        placeholder={i18n.t('choose_parking')}
                        value={this.getOptionValue('parcking', 'parking')}
                        isSearchable={false}
                        name="zoning"
                        options={lookups.parking}
                        onChange={(e) => this.setValue('parcking', e.value)}
                      />
                    </div>
                    
                    <div className="col-md-4 residential proffesional">
                      <label>{i18n.t('koinoxrista')}</label>
                      <Select 
                        className="select-element"
                        classNamePrefix="select"
                        placeholder={i18n.t('choose')}
                        value={this.state.form_data.koin_val === 1 ? {
                          label: i18n.t('no'),
                          value: 1
                        } :
                        {
                          label: i18n.t('yes'),
                          value: 0
                        }
                        }
                        isSearchable={false}
                        name="koin"
                        options={[
                          {
                            label: i18n.t('no'),
                            value: 1
                          },
                          {
                            label: i18n.t('yes'),
                            value: 0
                          }
                        ]}
                        onChange={(e) => this.setValue('koin_val', e.value)}
                      />
                      {
                        parseInt(this.state.form_data.koin_val) === 0 && 
                        <div className="residential proffesional">
                          <label>{i18n.t('koinoxrista_median')}</label>
                          <input className="form-control-light" type="number" value={this.getCurrentValue('koin')} onChange={(e) => this.setValue('koin', e.target.value)} placeholder={i18n.t('koinoxrista_median')}/>
                        </div>
                      }
                      
                    </div>
                    <div className="col-md-4 residential proffesional land">
                        <label>{i18n.t('Επενδυτικό')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Επενδυτικό')}
                            value={parseInt(this.state.form_data.ependitiko) === 1 ? {
                              label: i18n.t('yes'),
                              value: 1
                            } :
                            {
                              label: i18n.t('no'),
                              value: 0
                            }
                            }
                            isSearchable={false}
                            name="ependitiko"
                            options={[
                              {
                                label: i18n.t('no'),
                                value: 0
                              },
                              {
                                label: i18n.t('yes'),
                                value: 1
                              }
                            ]}
                            onChange={(e) => this.setValue('ependitiko', e.value)}
                          />
                          {
                            parseInt(this.state.form_data.ependitiko) === 1 && 
                            <div className="residential proffesional land">
                              <label>{i18n.t("Τιμή ενοικίου")}</label>
                              <input type="number" className="form-control-light" name="epe_rent" id="epe_rent" value={this.getCurrentValue('ependitiko_rent')} onChange={e => this.setValue('ependitiko_rent', e.target.value)} />
                            </div>
                          }
                    </div>
                    <div className="col-md-4 residential proffesional">
                        <label>{i18n.t('Αποθήκη')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Αποθήκη')}
                            value={parseInt(this.state.form_data.storage) === 1 ? {
                              label: i18n.t('yes'),
                              value: 1
                            } :
                            {
                              label: i18n.t('no'),
                              value: 0
                            }
                            }
                            isSearchable={false}
                            name="storage"
                            options={[
                              {
                                label: i18n.t('no'),
                                value: 0
                              },
                              {
                                label: i18n.t('yes'),
                                value: 1
                              }
                            ]}
                            onChange={(e) => this.setValue('storage', e.value)}
                          />
                          {
                            parseInt(this.state.form_data.storage) === 1 && 
                            <div className="residential proffesional">
                              <label>{i18n.t("Εμβαδόν αποθήκη")}</label>
                              <input type="number" className="form-control-light" id="storage_sq" name="storage_sq" value={this.getCurrentValue('storage_sq')} onChange={e => this.setValue('storage_sq', e.target.value)} />
                            </div>
                          }
                    </div>
                    <div className="col-md-4 land">
                        <label>{i18n.t('Υπάρχει κτίσμα')}</label>
                        <Select 
                            className="select-element"
                            classNamePrefix="select"
                            placeholder={i18n.t('Υπάρχει κτίσμα')}
                            value={parseInt(this.state.form_data.ktisma) === 1 ? {
                              label: i18n.t('yes'),
                              value: 1
                            } :
                            {
                              label: i18n.t('no'),
                              value: 0
                            }
                            }
                            isSearchable={false}
                            name="ktisma"
                            options={[
                              {
                                label: i18n.t('no'),
                                value: 0
                              },
                              {
                                label: i18n.t('yes'),
                                value: 1
                              }
                            ]}
                            onChange={(e) => this.setValue('ktisma', e.value)}
                          />
                          {
                            parseInt(this.state.form_data.ktisma) === 1 && 
                            <div className="land">
                              <label>{i18n.t("Εμβαδόν κτίσματος")}</label>
                              <input type="number" className="form-control-light" name="ktisma_sq" id="ktisma_sq" value={this.getCurrentValue('ktisma_sq')} onChange={e => this.setValue('ktisma_sq', e.target.value)} />
                            </div>
                          }
                    </div>
                    
                    <div className="col-md-4 residential proffesional">
                      <label>{i18n.t('conditions')}</label>
                      <Select 
                        className="select-element"
                        classNamePrefix="select"
                        placeholder={i18n.t('choose_conditions')}
                        value={this.getOptionValue('cond_id', 'conditions')}
                        isSearchable={false}
                        name="zoning"
                        options={lookups.conditions}
                        onChange={(e) => this.setValue('cond_id', e.value)}
                      />
                    </div>
                    <div className="col-md-4">
                      <label>{i18n.t('directions')}</label>
                      <Select 
                        className="select-element"
                        classNamePrefix="select"
                        placeholder={i18n.t('choose_directions')}
                        value={this.getOptionValue('directions', 'directions')}
                        isSearchable={false}
                        name="directions"
                        options={lookups.directions}
                        onChange={(e) => this.setValue('directions', e.value)}
                      />
                    </div>
                    <div className="col-md-4 residential proffesional">
                      <label>{i18n.t('orientation')}</label>
                      <Select 
                        className="select-element"
                        classNamePrefix="select"
                        placeholder={i18n.t('choose_orientation')}
                        value={this.getOptionValue('orient_id', 'orientations')}
                        isSearchable={false}
                        name="orientation"
                        options={lookups.orientations}
                        onChange={(e) => this.setValue('orient_id', e.value)}
                      />
                    </div>
                    <div className="col-md-4 residential proffesional">
                      <label>{i18n.t('public_service')}</label>
                      <Select 
                        className="select-element"
                        classNamePrefix="select"
                        placeholder={i18n.t('choose_public_service')}
                        value={this.getOptionValue('public_services', 'public_services')}
                        isSearchable={false}
                        name="public_services"
                        options={lookups.public_services}
                        onChange={(e) => this.setValue('public_services', e.value)}
                      />
                    </div>
                    <div className="col-md-4 residential">
                      <label>{i18n.t('master_rooms')}</label>
                      <input className="form-control-light" type="number" value={this.getCurrentValue('master')} onChange={(e) => this.setValue('master', e.target.value)} placeholder={i18n.t('set_master')}/>
                    </div>
                    <div className="col-md-4 residential proffesional">
                      <label>{i18n.t('sq_plot')}</label>
                      <input className="form-control-light" type="number" value={this.getCurrentValue('plot_sq')} onChange={(e) => this.setValue('plot_sq', e.target.value)} placeholder={i18n.t('set_plot_sq')}/>
                    </div>
                    <div className="col-md-4 residential proffesional">
                      <label>{i18n.t('construction_year')}</label>
                      <input className="form-control-light" type="number" value={this.getCurrentValue('year')} onChange={(e) => this.setValue('year', e.target.value)} placeholder={i18n.t('set_construction_year')}/>
                    </div>
                    <div className="col-md-4 residential proffesional">
                      <label>{i18n.t('balcony')}</label>
                      <input className="form-control-light" type="number" value={this.getCurrentValue('balk')} onChange={(e) => this.setValue('balk', e.target.value)} placeholder={i18n.t('set_balcony')}/>
                    </div>
                    <div className="col-md-4 residential">
                      <label>{i18n.t('set_student')}</label>
                      <Select 
                        className="select-element"
                        classNamePrefix="select"
                        placeholder={i18n.t('choose_student')}
                        value={this.state.form_data.student === 1 ? {
                          label: i18n.t('yes'),
                          value: 1
                        } :
                        {
                          label: i18n.t('no'),
                          value: 0
                        }
                        }
                        isSearchable={false}
                        name="student"
                        options={[
                          {
                            label: i18n.t('yes'),
                            value: 1
                          },
                          {
                            label: i18n.t('no'),
                            value: 0
                          }
                        ]}
                        onChange={(e) => this.setValue('student', e.value)}
                      />
                    </div>
                  </div>
                  { /**property_extra_values */}
                  <div className={`row optional-fields mt-4 property-form-checks ${this.state.fields_to_show === 'optional-fields' ? 'active' : 'hidden'}`}>
                    <div className="col-md-12">
                    <h4 className="property-form-title">{i18n.t('property_extra_values')}</h4>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 residential">
                      <div className="form-check">
                          <div className="button-custom">
                              <input className="checkbox" type="checkbox" id="furn" name="furn" value="1" checked={this.getCurrentValue('furn', true)} onChange={e => this.setCheckValue('furn', e)} />
                              <div className="details-button">
                                  <span>{i18n.t('ΟΧΙ')}</span>
                              </div>
                              <div className="layer"></div>
                          </div>
                          <label className="form-check-label" htmlFor="furn">{i18n.t("Επιπλωμένο")}</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 residential proffesional">
                        <div className="form-check ">
                            <div className="button-custom">
                                <input className="checkbox" type="checkbox" id="open" name="open" value="1" checked={this.getCurrentValue('open', true)} onChange={e => this.setCheckValue('open', e)} /> 
                                <div className="details-button">
                                    <span>{i18n.t('ΟΧΙ')}</span>
                                </div>
                                <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="open">{i18n.t("Ανοιχτωσιά")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 residential proffesional">
                        <div className="form-check ">
                            <div className="button-custom">
                                <input className="checkbox" type="checkbox" id="bright" name="bright" value="1" checked={this.getCurrentValue('bright', true)} onChange={e => this.setCheckValue('bright', e)} /> 
                                <div className="details-button">
                                    <span>{i18n.t('ΟΧΙ')}</span>
                                </div>
                                <div className="layer"></div>
                            </div>
                        <label className="form-check-label" htmlFor="bright">{i18n.t("Φωτεινό")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 residential proffesional">
                        <div className="form-check ">
                            <div className="button-custom">
                                <input className="checkbox" type="checkbox" id="tents" name="tents" value="1" checked={this.getCurrentValue('tents', true)} onChange={e => this.setCheckValue('tents', e)} /> 
                                <div className="details-button">
                                    <span>{i18n.t('ΟΧΙ')}</span>
                                </div>
                                <div className="layer"></div>
                            </div>
                        <label className="form-check-label" htmlFor="tents">{i18n.t("Τέντες")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 residential proffesional">
                        <div className="form-check ">
                            <div className="button-custom">
                            <input className="checkbox" type="checkbox" id="sun" name="sun" value="1" checked={this.getCurrentValue('sun', true)} onChange={e => this.setCheckValue('sun', e)} />
                            <div className="details-button">
                                <span>{i18n.t('ΟΧΙ')}</span>
                            </div>
                            <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="sun">{i18n.t("Ηλιακός")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 residential proffesional other">
                        <div className="form-check ">
                            <div className="button-custom">
                            <input className="checkbox" type="checkbox" id="paint" name="paint" value="1" checked={this.getCurrentValue('painted', true)} onChange={e => this.setCheckValue('painted', e)} />
                            <div className="details-button">
                                <span>{i18n.t('ΟΧΙ')}</span>
                            </div>
                            <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="paint">{i18n.t("Βαμμένο")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 residential proffesional">
                        <div className="form-check ">
                            <div className="button-custom">
                            <input className="checkbox" type="checkbox" id="ret" name="ret" value="1" checked={this.getCurrentValue('retire', true)} onChange={e => this.setCheckValue('retire', e)} />
                            <div className="details-button">
                                <span>{i18n.t('ΟΧΙ')}</span>
                            </div>
                            <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="ret">{i18n.t("Ρετιρέ")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 residential proffesional">
                        <div className="form-check ">
                            <div className="button-custom">
                            <input className="checkbox" type="checkbox" id="ac" name="ac" value="1" checked={this.getCurrentValue('ac', true)} onChange={e => this.setCheckValue('ac', e)} />
                            <div className="details-button">
                                <span>{i18n.t('ΟΧΙ')}</span>
                            </div>
                            <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="ac">{i18n.t("A/C")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 residential proffesional">
                        <div className="form-check ">
                            <div className="button-custom">
                            <input className="checkbox" type="checkbox" id="elevator" name="elevator" value="1" checked={this.getCurrentValue('elevator', true)} onChange={e => this.setCheckValue('elevator', e)} />
                            <div className="details-button">
                                <span>{i18n.t('ΟΧΙ')}</span>
                            </div>
                            <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="elevator">{i18n.t("Ανελκυστήρας")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 residential proffesional">
                        <div className="form-check ">
                            <div className="button-custom">
                            <input className="checkbox" type="checkbox" id="fireplace" name="fireplace" value="1" checked={this.getCurrentValue('fireplace', true)} onChange={e => this.setCheckValue('fireplace', e)} />
                            <div className="details-button">
                                <span>{i18n.t('ΟΧΙ')}</span>
                            </div>
                            <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="fireplace">{i18n.t("Τζάκι")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 residential proffesional">
                        <div className="form-check ">
                            <div className="button-custom">
                            <input className="checkbox" type="checkbox" id="kitchen_living" name="kitchen_living" value="1" checked={this.getCurrentValue('salok', true)} onChange={e => this.setCheckValue('salok', e)} />
                            <div className="details-button">
                                <span>{i18n.t('ΟΧΙ')}</span>
                            </div>
                            <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="kitchen_living">{i18n.t("Σαλοκουζίνα")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 residential proffesional">
                        <div className="form-check ">
                            <div className="button-custom">
                            <input className="checkbox" type="checkbox" id="pets" name="pets" value="1" checked={this.getCurrentValue('pets', true)} onChange={e => this.setCheckValue('pets', e)} />
                            <div className="details-button">
                                <span>{i18n.t('ΟΧΙ')}</span>
                            </div>
                            <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="pets">{i18n.t("Επιτρέπονται κατοικίδια")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 residential">
                        <div className="form-check ">
                            <div className="button-custom">
                            <input className="checkbox" type="checkbox" id="lux" name="lux" value="1" checked={this.getCurrentValue('luxury', true)} onChange={e => this.setCheckValue('luxury', e)} />
                            <div className="details-button">
                                <span>{i18n.t('ΟΧΙ')}</span>
                            </div>
                            <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="lux">{i18n.t("Πολυτελές")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 residential proffesional other">
                        <div className="form-check ">
                            <div className="button-custom">
                            <input className="checkbox" type="checkbox" id="amea" name="amea" value="1" checked={this.getCurrentValue('amea', true)} onChange={e => this.setCheckValue('amea', e)} />
                            <div className="details-button">
                                <span>{i18n.t('ΟΧΙ')}</span>
                            </div>
                            <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="amea">{i18n.t("Πρόσβαση ΑΜΕΑ")}</label>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 residential">
                        <div className="form-check ">
                            <div className="button-custom">
                                <input className="checkbox" type="checkbox" id="neoclassic" name="neoclassic" value="1" checked={this.getCurrentValue('neoclassic', true)} onChange={e => this.setCheckValue('neoclassic', e)} />
                                <div className="details-button">
                                    <span>{i18n.t('ΟΧΙ')}</span>
                                </div>
                                <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="neoclassic">{i18n.t("Νεοκλασικό")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 residential proffesional">
                        <div className="form-check ">
                            <div className="button-custom">
                                <input className="checkbox" type="checkbox" id="alarm" name="alarm" value="1" checked={this.getCurrentValue('alarm', true)} onChange={e => this.setCheckValue('alarm', e)} />
                                <div className="details-button">
                                    <span>{i18n.t('ΟΧΙ')}</span>
                                </div>
                                <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="alarm">{i18n.t("Συναγερμός")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 residential proffesional">
                        <div className="form-check ">
                            <div className="button-custom">
                                <input className="checkbox" type="checkbox" id="devices" name="devices" value="1" checked={this.getCurrentValue('devices', true)} onChange={e => this.setCheckValue('devices', e)} />
                                <div className="details-button">
                                    <span>{i18n.t('ΟΧΙ')}</span>
                                </div>
                                <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="devices">{i18n.t("Ηλεκτρικές συσκευές")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 proffesional">
                        <div className="form-check ">
                            <div className="button-custom">
                                <input className="checkbox" type="checkbox" id="equipped" name="equipped" value="1" checked={this.getCurrentValue('equipped', true)} onChange={e => this.setCheckValue('equipped', e)} />
                                <div className="details-button">
                                    <span>{i18n.t('ΟΧΙ')}</span>
                                </div>
                                <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="equipped">{i18n.t("Με εξοπλισμό")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 residential proffesional other">
                        <div className="form-check ">
                            <div className="button-custom">
                                <input className="checkbox" type="checkbox" id="retire_last" name="retire_last" value="1" checked={this.getCurrentValue('retire_last', true)} onChange={e => this.setCheckValue('retire_last', e)} />
                                <div className="details-button">
                                    <span>{i18n.t('ΟΧΙ')}</span>
                                </div>
                                <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="retire_last">{i18n.t("Τελευταίος όροφος")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 residential proffesional">
                        <div className="form-check ">
                            <div className="button-custom">
                                <input className="checkbox" type="checkbox" id="road" name="road" value="1" checked={this.getCurrentValue('road', true)} onChange={e => this.setCheckValue('road', e)} />
                                <div className="details-button">
                                    <span>{i18n.t('ΟΧΙ')}</span>
                                </div>
                                <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="road">{i18n.t("Κεντρική οδός")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 residential">
                        <div className="form-check ">
                            <div className="button-custom">
                                <input className="checkbox" type="checkbox" id="satellite" name="satellite" value="1" checked={this.getCurrentValue('satellite', true)} onChange={e => this.setCheckValue('satellite', e)} />
                                <div className="details-button">
                                    <span>{i18n.t('ΟΧΙ')}</span>
                                </div>
                                <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="satellite">{i18n.t("Δορυφορική κεραία")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 residential">
                        <div className="form-check ">
                            <div className="button-custom">
                                <input className="checkbox" type="checkbox" id="nightpower" name="nightpower" value="1" checked={this.getCurrentValue('nightpower', true)} onChange={e => this.setCheckValue('nightpower', e)} />
                                <div className="details-button">
                                    <span>{i18n.t('ΟΧΙ')}</span>
                                </div>
                                <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="nightpower">{i18n.t("Νυχτερινό ρεύμα")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 residential proffesional">
                        <div className="form-check ">
                            <div className="button-custom">
                                <input className="checkbox" type="checkbox" id="preserved" name="preserved" value="1" checked={this.getCurrentValue('preserved', true)} onChange={e => this.setCheckValue('preserved', e)} />
                                <div className="details-button">
                                    <span>{i18n.t('ΟΧΙ')}</span>
                                </div>
                                <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="preserved">{i18n.t("Διατηρητέο")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 residential">
                        <div className="form-check ">
                            <div className="button-custom">
                                <input className="checkbox" type="checkbox" id="orofodiamerisma" name="orofodiamerisma" value="1" checked={this.getCurrentValue('orofodiamerisma', true)} onChange={e => this.setCheckValue('orofodiamerisma', e)} />
                                <div className="details-button">
                                    <span>{i18n.t('ΟΧΙ')}</span>
                                </div>
                                <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="orofodiamerisma">{i18n.t("Οροφοδιαμέρισμα")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 residential proffesional">
                        <div className="form-check ">
                            <div className="button-custom">
                                <input className="checkbox" type="checkbox" id="doubleglass" name="doubleglass" value="1" checked={this.getCurrentValue('doubleglass', true)} onChange={e => this.setCheckValue('doubleglass', e)} />
                                <div className="details-button">
                                    <span>{i18n.t('ΟΧΙ')}</span>
                                </div>
                                <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="doubleglass">{i18n.t("Διπλά τζάμια")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 residential proffesional">
                        <div className="form-check ">
                            <div className="button-custom">
                                <input className="checkbox" type="checkbox" id="pestnet" name="pestnet" value="1" checked={this.getCurrentValue('pestnet', true)} onChange={e => this.setCheckValue('pestnet', e)} />
                                <div className="details-button">
                                    <span>{i18n.t('ΟΧΙ')}</span>
                                </div>
                                <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="pestnet">{i18n.t("Σίτες")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 residential proffesional">
                        <div className="form-check ">
                            <div className="button-custom">
                                <input className="checkbox" type="checkbox" id="internalstairs" name="internalstairs" value="1" checked={this.getCurrentValue('internalstairs', true)} onChange={e => this.setCheckValue('internalstairs', e)} />
                                <div className="details-button">
                                    <span>{i18n.t('ΟΧΙ')}</span>
                                </div>
                                <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="internalstairs">{i18n.t("Εσωτερική σκάλα")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 land">
                        <div className="form-check ">
                            <div className="button-custom">
                                <input className="checkbox" type="checkbox" id="train" name="train" value="1" checked={this.getCurrentValue('train', true)} onChange={e => this.setCheckValue('train', e)} />
                                <div className="details-button">
                                    <span>{i18n.t('ΟΧΙ')}</span>
                                </div>
                                <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="train">{i18n.t("Σιδηροδρομική γραμμή")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 residential">
                        <div className="form-check ">
                            <div className="button-custom">
                                <input className="checkbox" type="checkbox" id="bbq" name="bbq" value="1" checked={this.getCurrentValue('bbq', true)} onChange={e => this.setCheckValue('bbq', e)} />
                                <div className="details-button">
                                    <span>{i18n.t('ΟΧΙ')}</span>
                                </div>
                                <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="bbq">{i18n.t("BBQ")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 residential">
                        <div className="form-check ">
                            <div className="button-custom">
                                <input className="checkbox" type="checkbox" id="attic" name="attic" value="1" checked={this.getCurrentValue('attic', true)} onChange={e => this.setCheckValue('attic', e)} />
                                <div className="details-button">
                                    <span>{i18n.t('ΟΧΙ')}</span>
                                </div>
                                <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="attic">{i18n.t("Σοφίτα")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 residential proffesional">
                        <div className="form-check ">
                            <div className="button-custom">
                                <input className="checkbox" type="checkbox" id="playroom" name="playroom" value="1" checked={this.getCurrentValue('playroom', true)} onChange={e => this.setCheckValue('playroom', e)} />
                                <div className="details-button">
                                    <span>{i18n.t('ΟΧΙ')}</span>
                                </div>
                                <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="playroom">{i18n.t("Playroom")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 proffesional">
                        <div className="form-check ">
                            <div className="button-custom">
                                <input className="checkbox" type="checkbox" id="furred_ceiling" name="furred_ceiling" value="1" checked={this.getCurrentValue('furred_ceiling', true)} onChange={e => this.setCheckValue('furred_ceiling', e)} />
                                <div className="details-button">
                                    <span>{i18n.t('ΟΧΙ')}</span>
                                </div>
                                <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="furred_ceiling">{i18n.t("Ψευδοροφή")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 proffesional">
                        <div className="form-check ">
                            <div className="button-custom">
                                <input className="checkbox" type="checkbox" id="structured_wiring" name="structured_wiring" value="1" checked={this.getCurrentValue('structured_wiring', true)} onChange={e => this.setCheckValue('structured_wiring', e)} />
                                <div className="details-button">
                                    <span>{i18n.t('ΟΧΙ')}</span>
                                </div>
                                <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="structured_wiring">{i18n.t("Δομημένη καλωδίωση")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 proffesional">
                        <div className="form-check ">
                            <div className="button-custom">
                                <input className="checkbox" type="checkbox" id="security_shutters" name="security_shutters" value="1" checked={this.getCurrentValue('security_shutters', true)} onChange={e => this.setCheckValue('security_shutters', e)} />
                                <div className="details-button">
                                    <span>{i18n.t('ΟΧΙ')}</span>
                                </div>
                                <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="security_shutters">{i18n.t("Ρολά ασφαλείας")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 residential proffesional">
                        <div className="form-check ">
                            <div className="button-custom">
                                <input className="checkbox" type="checkbox" id="stair_steps" name="stair_steps" value="1" checked={this.getCurrentValue('stair_steps', true)} onChange={e => this.setCheckValue('stair_steps', e)} />
                                <div className="details-button">
                                    <span>{i18n.t('ΟΧΙ')}</span>
                                </div>
                                <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="stair_steps">{i18n.t("Σκαλιά εισόδου")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 residential proffesional">
                        <div className="form-check ">
                            <div className="button-custom">
                                <input className="checkbox" type="checkbox" id="professional" name="professional" value="1" checked={this.getCurrentValue('prof', true)} onChange={e => this.setCheckValue('prof', e)} />
                                <div className="details-button">
                                    <span>{i18n.t('ΟΧΙ')}</span>
                                </div>
                                <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="professional">{i18n.t("Επαγγελματική Στέγη")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 proffesional">
                        <div className="form-check ">
                            <div className="button-custom">
                                <input className="checkbox" type="checkbox" id="ygion" name="ygion" value="1" checked={this.getCurrentValue('ygion', true)} onChange={e => this.setCheckValue('ygion', e)} />
                                <div className="details-button">
                                    <span>{i18n.t('ΟΧΙ')}</span>
                                </div>
                                <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="ygion">{i18n.t("Χρήση Υγειονομικού")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 proffesional">
                        <div className="form-check ">
                            <div className="button-custom">
                                <input className="checkbox" type="checkbox" id="anel_fort" name="anel_fort" value="1" checked={this.getCurrentValue('anel_fort', true)} onChange={e => this.setCheckValue('anel_fort', e)} />
                                <div className="details-button">
                                    <span>{i18n.t('ΟΧΙ')}</span>
                                </div>
                                <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="anel_fort">{i18n.t("Ανελκυστήρας φορτίων")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 proffesional">
                        <div className="form-check ">
                            <div className="button-custom">
                                <input className="checkbox" type="checkbox" id="rampa" name="rampa" value="1" checked={this.getCurrentValue('rampa', true)} onChange={e => this.setCheckValue('rampa', e)} />
                                <div className="details-button">
                                    <span>{i18n.t('ΟΧΙ')}</span>
                                </div>
                                <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="rampa">{i18n.t("Ράμπα εκφόρτωσης")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 proffesional">
                        <div className="form-check ">
                            <div className="button-custom">
                                <input className="checkbox" type="checkbox" id="yard" name="yard" value="1" checked={this.getCurrentValue('yard', true)} onChange={e => this.setCheckValue('yard', e)} />
                                <div className="details-button">
                                    <span>{i18n.t('ΟΧΙ')}</span>
                                </div>
                                <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="yard">{i18n.t("Προαύλιο")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 land">
                        <div className="form-check ">
                            <div className="button-custom">
                                <input className="checkbox" type="checkbox" id="artio" name="artio" value="1" checked={this.getCurrentValue('artio', true)} onChange={e => this.setCheckValue('artio', e)} />
                                <div className="details-button">
                                    <span>{i18n.t('ΟΧΙ')}</span>
                                </div>
                                <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="artio">{i18n.t("Άρτιο")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 land">
                        <div className="form-check ">
                            <div className="button-custom">
                                <input className="checkbox" type="checkbox" id="oikodom" name="oikodom" value="1" checked={this.getCurrentValue('oikodom', true)} onChange={e => this.setCheckValue('oikodom', e)} />
                                <div className="details-button">
                                    <span>{i18n.t('ΟΧΙ')}</span>
                                </div>
                                <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="oikodom">{i18n.t("Οικοδομήσιμο")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 land">
                        <div className="form-check ">
                            <div className="button-custom">
                                <input className="checkbox" type="checkbox" id="adeia_oik" name="adeia_oik" value="1" checked={this.getCurrentValue('adeia_oik', true)} onChange={e => this.setCheckValue('adeia_oik', e)} />
                                <div className="details-button">
                                    <span>{i18n.t('ΟΧΙ')}</span>
                                </div>
                                <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="adeia_oik">{i18n.t("Με άδεια δόμησης")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 residential land">
                        <div className="form-check ">
                            <div className="button-custom">
                                <input className="checkbox" type="checkbox" id="panoramiko" name="panoramiko" value="1" checked={this.getCurrentValue('panoramiko', true)} onChange={e => this.setCheckValue('panoramiko', e)} />
                                <div className="details-button">
                                    <span>{i18n.t('ΟΧΙ')}</span>
                                </div>
                                <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="panoramiko">{i18n.t("Πανοραμικό")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 residential land">
                        <div className="form-check ">
                            <div className="button-custom">
                                <input className="checkbox" type="checkbox" id="paraliako" name="paraliako" value="1" checked={this.getCurrentValue('paraliako', true)} onChange={e => this.setCheckValue('paraliako', e)} />
                                <div className="details-button">
                                    <span>{i18n.t('ΟΧΙ')}</span>
                                </div>
                                <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="paraliako">{i18n.t("Παραλιακό")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 land">
                        <div className="form-check ">
                            <div className="button-custom">
                                <input className="checkbox" type="checkbox" id="perifragmeno" name="perifragmeno" value="1" checked={this.getCurrentValue('perifragmeno', true)} onChange={e => this.setCheckValue('perifragmeno', e)} />
                                <div className="details-button">
                                    <span>{i18n.t('ΟΧΙ')}</span>
                                </div>
                                <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="perifragmeno">{i18n.t("Περιφραγμένο")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 land">
                        <div className="form-check ">
                            <div className="button-custom">
                                <input className="checkbox" type="checkbox" id="geotrisi" name="geotrisi" value="1" checked={this.getCurrentValue('geotrisi', true)} onChange={e => this.setCheckValue('geotrisi', e)} />
                                <div className="details-button">
                                    <span>{i18n.t('ΟΧΙ')}</span>
                                </div>
                                <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="geotrisi">{i18n.t("Με γεώτρηση")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 land">
                        <div className="form-check ">
                            <div className="button-custom">
                                <input className="checkbox" type="checkbox" id="nero" name="nero" value="1" checked={this.getCurrentValue('nero_reuma', true)} onChange={e => this.setCheckValue('nero_reuma', e)} />
                                <div className="details-button">
                                    <span>{i18n.t('ΟΧΙ')}</span>
                                </div>
                                <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="nero">{i18n.t("Με νερό")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 land">
                        <div className="form-check ">
                            <div className="button-custom">
                                <input className="checkbox" type="checkbox" id="reuma" name="reuma" value="1" checked={this.getCurrentValue('reuma', true)} onChange={e => this.setCheckValue('reuma', e)} />
                                <div className="details-button">
                                    <span>{i18n.t('ΟΧΙ')}</span>
                                </div>
                                <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="reuma">{i18n.t("Με ρεύμα")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 land">
                        <div className="form-check ">
                            <div className="button-custom">
                                <input className="checkbox" type="checkbox" id="gass" name="gass" value="1" checked={this.getCurrentValue('gass', true)} onChange={e => this.setCheckValue('gass', e)} />
                                <div className="details-button">
                                    <span>{i18n.t('ΟΧΙ')}</span>
                                </div>
                                <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="gass">{i18n.t("Με αέριο")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 land">
                        <div className="form-check ">
                            <div className="button-custom">
                                <input className="checkbox" type="checkbox" id="tel" name="tel" value="1" checked={this.getCurrentValue('tel', true)} onChange={e => this.setCheckValue('tel', e)} />
                                <div className="details-button">
                                    <span>{i18n.t('ΟΧΙ')}</span>
                                </div>
                                <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="tel">{i18n.t("Με τηλέφωνο")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 land">
                        <div className="form-check ">
                            <div className="button-custom">
                                <input className="checkbox" type="checkbox" id="optics" name="optics" value="1" checked={this.getCurrentValue('optics', true)} onChange={e => this.setCheckValue('optics', e)} />
                                <div className="details-button">
                                    <span>{i18n.t('ΟΧΙ')}</span>
                                </div>
                                <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="optics">{i18n.t("Με οπτικές ίνες")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 land">
                        <div className="form-check ">
                            <div className="button-custom">
                                <input className="checkbox" type="checkbox" id="goniako" name="goniako" value="1" checked={this.getCurrentValue('goniako', true)} onChange={e => this.setCheckValue('goniako', e)} />
                                <div className="details-button">
                                    <span>{i18n.t('ΟΧΙ')}</span>
                                </div>
                                <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="goniako">{i18n.t("Γωνιακό")}</label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 py-2 land">
                        <div className="form-check ">
                            <div className="button-custom">
                                <input className="checkbox" type="checkbox" id="antiparoxi" name="antiparoxi" value="1" checked={this.getCurrentValue('antiparoxi', true)} onChange={e => this.setCheckValue('antiparoxi', e)} />
                                <div className="details-button">
                                    <span>{i18n.t('ΟΧΙ')}</span>
                                </div>
                                <div className="layer"></div>
                            </div>
                            <label className="form-check-label" htmlFor="antiparoxi">{i18n.t("Αντιπαροχή")}</label>
                        </div>
                    </div>
                    
                  </div>
                  <div className={`row optional-fields mt-4 ${this.state.fields_to_show === 'optional-fields' ? 'active' : 'hidden'}`}>
                    <div className="col-md-3 my-4">
                      <button className="btn btn-primary text-white" onClick={() => this.goTo('location-fields')}>{i18n.t('previous_step')}</button>
                    </div>
                    <div className="col-md-3 my-4 offset-md-6">
                      <button className="btn btn-primary text-white" onClick={() => this.goTo('publish-networks')}>{i18n.t('next_step')}</button>
                    </div>
                  </div>
                  <div className={`row publish-networks mt-4 property-form-checks ${this.state.fields_to_show === 'publish-networks' ? 'active' : 'hidden'}`}>
                    <div className="col-lg-3 col-md-4 col-sm-12 mini-margin">
                      <div className="form-check form-check-single form-check-simple">
                          <div className="button-custom">
                              <input 
                                className="checkbox" 
                                type="checkbox" 
                                id="publish-profile" 
                                name="publish-profile" 
                                value="1" 
                                checked={parseInt(this.state.publish.profile) === 1} 
                                onChange={e => this.setState({
                                  publish: {
                                    ...this.state.publish,
                                    profile: e.target.checked ? 1 : 0
                                  }
                                })} 
                              />
                              <div className="details-button">
                                  <span>{i18n.t('ΟΧΙ')}</span>
                              </div>
                              <div className="layer"></div>
                          </div>
                          <label className="form-check-label" htmlFor="publish-profile">{i18n.t("publish_on_profile")}</label>
                      </div>
                    </div>
                    {
                      this.state.networks.map(network => {
                        return (
                          <div className="col-lg-3 col-md-4 col-sm-12 mini-margin">
                            <div className="form-check form-check-single form-check-simple">
                                <div className="button-custom">
                                    <input
                                      className="checkbox" 
                                      type="checkbox" 
                                      id={`publish-${network.id}`} 
                                      name={`publish-${network.id}`} 
                                      value="1" 
                                      checked={this.state.publish.networks.filter(n => parseInt(n) === parseInt(network.id)).length > 0} 
                                      onChange={e => this.setPublishedNetworks(e.target.checked, network.id)} 
                                    />
                                    <div className="details-button">
                                        <span>{i18n.t('ΟΧΙ')}</span>
                                    </div>
                                    <div className="layer"></div>
                                </div>
                                <label className="form-check-label" htmlFor={`publish-${network.id}`}>{network.name}</label>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                  <div className={`row publish-networks mt-4 ${this.state.fields_to_show === 'publish-networks' ? 'active' : 'hidden'}`}>
                    <div className="col-md-3 my-4">
                      <button className="btn btn-primary text-white" onClick={() => this.goTo('optional-fields')}>{i18n.t('previous_step')}</button>
                    </div>
                    <div className="col-md-3 my-4 offset-md-6">
                      <button className="btn btn-primary text-white" onClick={() => this.storeProperty()}>{i18n.t('save')}</button>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        <Rightchat />
        <Popupchat />
        <Appfooter /> 
      </Fragment>
    );
  }
}

export default PropertyForm;