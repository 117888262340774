import React,{Component, createRef} from 'react';
import { Tooltip } from '@mui/material';
import Button from '../components/Button';
import i18n from '../i18n';
import Utils from "../utils"
import storage from '../storage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPuzzlePiece, faClose } from '@fortawesome/free-solid-svg-icons';
import request from '../request';
import ModalCompose from "../components/ModalCompose";
import ModalGeneric from "../components/ModalGeneric";
import Swal from 'sweetalert2'
import socket from '../socket';
import { Link } from 'react-router-dom';

class RequestCard extends Component {
	constructor(props) {
		super(props);

		this.searchRef = createRef()
		this.elementRef = createRef(null)
		this.state = {
			selected_properties: [],
			search_results: [],
			request_text: '',
			showModal: false,
			show_suggestions: false,
			isMenuOpen: false,
		};
	}

	componentDidMount() {
		this.unlistenLocale = i18n.onChangeLocale(() => {
			this.forceUpdate();
		});
		this.inputClicked = () => {
			this.setState({
				show_suggestions: true
			})
		}
		this.documentClicked = (e) => {
			
			if (this.searchRef.current && this.searchRef.current !== e.target && !this.searchRef.current.contains(e.target) && this.state.show_suggestions === true) {
				this.setState({
					show_suggestions: false
				})
            }

			if (this.elementRef.current && !this.elementRef.current.contains(e.target) && this.state.isMenuOpen === true) {
				this.setState({
				  isMenuOpen: false
				})
			  }
			
		}
		if (this.searchRef.current)
			this.searchRef.current.addEventListener('click', this.inputClicked)
		document.addEventListener('click', this.documentClicked)
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.documentClicked)
		this.unlistenLocale()
	}

	toggleMenuOpen = () => this.setState({ isMenuOpen: !this.state.isMenuOpen });

	cancelCooperationRequest(request_id){
		request.delete(`collab/type/2/id/${request_id}`, {
		  type: 2,
		  related_id: request_id
		}).then((response) => {
		  if (response.data.attempt_status === 200) {
			if (this.props.cancelCooperationRequest) {
				this.props.cancelCooperationRequest({
					id: request_id,
					collab: null
				})
			}
			
		  }
		});

		
	}

	cooperationRequestInit(id) {
		// request.get(`cross_properties/${id}`).then(response => {
		// 	if (response.status === 200) {
		// 		this.setState({
		// 			selected_properties: response.data
		// 		})
		// 	}
		// })
		this.setState({
			showModal: true
		})
	}

	cooperationRequest(){
		const request_id = this.props.request.id
		request.put(`collab`, {
			type: 2,
			related_id: request_id,
			refs: this.state.selected_properties.map(s => s.id),
			message: this.state.request_text
		}).then((response) => {
			if (this.props.cooperationRequest) {
				if (response.data.attempt_status === 200 && response.data.data !== null) {
					this.props.cooperationRequest({
						id: request_id,
						collab: response.data.data,
						spam_collab: false
					})
					socket.send({
						type: 'event',
						data: {
						  class: 'cooperation-request',
						  realtor: this.props.request.realtor,
						  cooperationId:  response.data.data.cooperations_id
						},
					});
					this.setState({
						...this.state,
						showModal: false
					}, () => {
						Swal.fire({
							title: i18n.t('cooperation_request_sended'),
							text: i18n.t('you_will_be_informed_if_coop_accepted'),
							icon: 'success'
						})
					})
				} else if (response.data.attempt_status === 429) {
					this.props.cooperationRequest({
						id: request_id,
						collab: null,
						spam_collab: true
					})
					Swal.fire({
						title: i18n.t('cooperation_request_not_sended'),
						text: i18n.t('wait_1_day_before_resending_request'),
						icon: 'error'
					})
				} else if (response.data.attempt_status === 201) {
					this.props.cooperationRequest({
						id: request_id,
						collab: null,
						spam_collab: false
					})
					Swal.fire({
						title: i18n.t('request_already_sended'),
						text: i18n.t('you_will_be_informed_if_coop_accepted'),
						icon: 'info'
					})
				} else if (response.data.attempt_status === 401) {
					this.props.cooperationRequest({
						id: request_id,
						collab: null,
						spam_collab: false
					})
					Swal.fire({
						title: i18n.t('cooperation_request_not_sended'),
						text: i18n.t('you_are_not_connected_with_user'),
						icon: 'error'
					})
				}
			}
		
		});
	}

	searchProperty(str) {
		if (str.length > 2) {
			request.get(`my_properties/${str}`).then(response => {
				this.setState({
					...this.state,
					search_results: response.data
				})
			})
		}
	}

	render() {
		const menuClass = `${this.state.isMenuOpen ? " show" : ""}`;
		const {request, cooperationRequest, sendMessage, cancelCooperationRequest} = this.props;
		const meID = storage.me && storage.me.realtors_id;
		return (
			<div className={`card d-block w-100 border-0 mb-3 bg-white rounded-3 pe-4 pt-4 request-card pb-4 ${(this.props.system) ? 'system-card' : ''}`}  style={{paddingLeft: "120px"}}>
				{
					this.props.match_icon &&
					<FontAwesomeIcon className='match-icon' icon={faPuzzlePiece} />
				}
				{this.props.network_owner ? 
				<div className="ms-auto" style={{position: 'relative'}} ref={this.elementRef}>
					<div onClick={this.toggleMenuOpen} className={`ms-auto pointer ${menuClass}`} style={{textAlign: 'right'}} id={`dropdownMenu${request.id}`} data-bs-toggle="dropdown" aria-expanded="false">
					<i className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"></i>
					</div>
					<div className={`dropdown-menu p-3 right-0 rounded-xxl border-0 shadow-lg ${menuClass}`} aria-labelledby={`dropdownMenu${request.id}`}>
					
					<div onClick={() => {
						if (this.props.onRemoveFromNetwork) this.props.onRemoveFromNetwork(request)
					}} className="card-body p-0 d-flex">
						<i className="feather-trash-2 text-grey-500 me-2 font-lg"></i>
						<h4 className="fw-600 text-grey-900 font-xssss mt-0 me-3 pointer">{i18n.t('button_delete')} <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500 mw-190">{i18n.t('button_delete_request_from_network')}</span></h4>
					</div>
					</div>
				</div> 
				: '' }
				<div className='requests-details'>
					<Tooltip title={request.realtor_name} placement="top">
						<Link to={`/members/${request.realtor}`}><img src={`${request.realtor_image}`} onError={e => e.target.src = "https://network.realstatus.net/images/profile_image/default.jpg"} alt="job-avater" className="img-avatar-request position-absolute bg-lightblue2 w65 ms-4 left-0 img-circle" /></Link>
					</Tooltip>
				
					<Link to={`/request/${request.id}`}>
					<h4 className="font-xss fw-700 text-grey-900 mb-3 pe-4">
						{i18n.get_data('purpose', request)}&nbsp;-&nbsp;{request.categ !== '' ? i18n.get_data('categ', request) : i18n.get_data('categ_basic_name', request)}<br />
					</h4>
					</Link>
				</div>
				<Tooltip title={i18n.t('code')} placement="top">
					<h5 className="d-inline-block font-xssss mb-2 text-grey-500 fw-600 mr-5">
						<i className="ti-info-alt mr-5"></i> {request.id}
					</h5>
				</Tooltip>|
				<Tooltip title={i18n.t('date_added')} placement="top">
					<h6 className="d-inline-block text-grey-900 font-xssss text-dark mr-5"><i className='mr-5 ml-5 ti-calendar'></i>{request.date}</h6>
				</Tooltip>|
				<Tooltip title={i18n.t('date_modified')} placement="top">
					<h6 className="d-inline-block text-grey-900 font-xssss text-dark"><i className='mr-5 ml-5 ti-pencil'></i>{Utils.getMomentText(request.mod_date)}</h6>
				</Tooltip>
				<h5 className="font-xssss mb-2 text-grey-500 fw-600">
					<span className="text-grey-900 font-xssss text-dark">{i18n.t('price')} : </span> {request.price_min}€&nbsp;-&nbsp;{request.price_max}€
				</h5>
				<h5 className="font-xssss mb-2 text-grey-500 fw-600">
					<span className="text-grey-900 font-xssss text-dark">{i18n.t('sqm')} : </span> {request.sq_min}&nbsp;-&nbsp;{request.sq_max}
				</h5>
				{
					request.categ_basic === '2'
						? parseInt(request.rooms) > 0 ?
							<h5 className="font-xssss mb-2 text-grey-500 fw-600">
								<span className="text-grey-900 font-xssss text-dark">{i18n.t('spaces')} : </span> {request.rooms}
							</h5> : ''
						: ''
				}
				{
					request.categ_basic === '1'
						? parseInt(request.rooms) > 0 ?
							<h5 className="font-xssss mb-2 text-grey-500 fw-600">
								<span className="text-grey-900 font-xssss text-dark">{i18n.t('bedrooms')} : </span> {request.rooms}
							</h5> : ''
						: ''
				}
				<div className="font-xssss mb-2 text-grey-500 fw-600">
					{
						request.networks && request.networks.length > 0 
						? 
							<div>
								<i className="mr-5 font-xsssss btn-round-xs bg-success ti-check text-white"></i>
								<span className="text-grey-900 font-xssss text-dark">{i18n.t('published_net')}: </span>
								{
									request.networks.map( (network, index) => {
										return <h6 key={index} className={`d-inline-block p-1 alert-primary text-secondary fw-600 font-xssss rounded-5 me-2`}>{network}</h6>
									})
								}
							</div>
						: <div>
							<i className="mr-5 font-xsssss btn-round-xss bg-danger text-white"></i>
							<span className="text-grey-900 font-xssss text-dark">{i18n.t('no_published_net')} </span>
						  </div>
					}
					
				</div>
				<div className='mb-5'>
					<h6 className={`d-inline-block p-2 alert-secondary text-secondary fw-600 font-xssss rounded-5 me-2 locations-item`} onClick={() => {
						if (this.props.onLocationsClick && typeof this.props.onLocationsClick !== 'undefined') {
							this.props.onLocationsClick(request)
						}
					}}>
						{i18n.get_data('drawn_locations', request)}
					</h6>
				</div>
				{
					request.realtor !== meID ?
						
							<div className="position-absolute bottom-15 mb-3 right-15 d-flex">
								{
									typeof request.collab === 'undefined' || request.collab === null ?
									!request.spam_collab ?
									<>
										{/* <Button onClick={() => sendMessage(request.id)} className="ms-auto d-none align-items-center d-lg-block bg-secondary p-2 rounded-xl text-white font-xssss fw-700">{i18n.t('Αποστολή μηνύματος')}</Button> */}
										<Button onClick={() => this.cooperationRequestInit(request.id)} className="ms-2 align-items-center d-lg-block d-block bg-primary p-2 rounded-xl text-white font-xssss fw-700">{i18n.t('cooperation_request')}</Button>
									</>
										: <Button className="ms-2 align-items-center d-lg-block bg-danger p-2 rounded-3 text-white font-xssss fw-700">{i18n.t('Ακυρώσατε πρόσφατα αίτημα συνεργασίας')}</Button>
									: request.collab.cooperations_status == 0 ? 
										<Button onClick={() => this.cancelCooperationRequest(request.id)} className="ms-2 align-items-center d-lg-block d-block bg-danger p-2 rounded-xl text-white font-xssss fw-700">{i18n.t('Ακύρωση αιτήματος')}</Button>
										: null
								}
							</div>
						
					: null	
				}
				<ModalGeneric
					show={this.state.showModal}
					handleClose={() => this.setState({
						showModal: false
					})}
					header={<h4>{i18n.t('cooperation_request')}</h4>}
					footer={<div className="card-body p-0 d-flex space-between open-house-footer align-center">
								<div className="d-flex">
									<button onClick={() => this.cooperationRequest()} className="bg-success btn-action btn-accept p-2 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-600">{i18n.t('send')}</button>
									<button onClick={() => this.setState({
						showModal: false
					})}className="bg-grey btn-action btn-accept p-2 ms-2 z-index-1 rounded-3 font-xsssss text-uppercase fw-600">{i18n.t('button_cancel_request')}</button>
								</div>
							</div>}
				>
					<div className="card w-100 border-0 p-4 mb-3">
						<div className="card-body mt-2 p-0">
							
							<form>
							<div className="row mt-4">
									{/* <div className="col-md-12">
										<div className="form-group p-relative">
											<label className="mb-2">{i18n.t('related_property_for_collaboration')}</label>
											{
												this.state.selected_properties &&
												this.state.selected_properties.map(s => {
													return (
														<div className={`property-suggestion`} onClick={() => this.setState({
															...this.state,
															selected_properties: this.state.selected_properties.filter(sp => sp.id !== s.id)
														})}>
															<img src={s.thumbnail} />
															<div className='property-title'>
																<b>#{s.id}</b><br/>
																<span>{i18n.get_data('title', s)}</span>
															</div>
															<FontAwesomeIcon icon={faClose} />
														</div>
													)
												})
												
											}
											<input 
												type="text" 
												className='form-control' 
												onChange={(e) => this.searchProperty(e.target.value)}
												placeholder={i18n.t('advert_code')}
												ref={this.searchRef}
											/>
											<div className={`search-results scroll-bar ${this.state.show_suggestions ? '' : 'hidden'}`}>
												{
													this.state.search_results && 
													this.state.search_results.filter(s => this.state.selected_properties.filter(sp => sp.id === s.id).length === 0).map(s => {
														return (
															<div className={`property-suggestion`} onClick={() => this.setState({
																...this.state,
																selected_properties: [...this.state.selected_properties, s]
															})}>
																<img src={s.thumbnail} />
																<div className='property-title'>
																	<b>#{s.id}</b><br/>
																	<span>{i18n.get_data('title', s)}</span>
																</div>
															</div>
														)
													})
												}
											</div>
										</div>
									</div> */}
								</div>
								<div className="row mt-4">
									<div className="col-md-12">
										<div className="form-group">
											<label className="mb-2">{i18n.t('request_content')}</label>
											<textarea rows="6" value={this.state.request_text} onChange={(e) => this.setState({...this.state, request_text: e.target.value})} className="form-control mh-125" placeholder={i18n.t('describe_your_reason_of_request_in_a_few_words')}/>
											<p className="error_message d-none">*Υποχρεωτικό πεδίο</p>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</ModalGeneric>
			</div>
		);
	}
}

export default RequestCard;