import React, { Component , createRef, Fragment } from "react";
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Pagetitle from '../components/Pagetitle';
import Appfooter from '../components/Appfooter';
import PropertyCard from "../components/PropertyCard";
import i18n from "../i18n";
import request from "../request";
import * as L from "leaflet";
import Load from '../components/Load'
import Rightchat from "../components/Rightchat";
import Popupchat from "../components/Popupchat";
import '../css/leaflet.css'
import '../css/MarkerCluster.Default.css'
import '../css/MarkerCluster.css'
import SearchProperties from "../components/SearchProperties"
import uriHelper from "../uri_helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import ActiveFilters from "../components/ActiveFilters";
import 'leaflet-draw/dist/leaflet.draw.css'
import InteractiveMap from "../components/InteractiveMap";
import ModalGeneric from "../components/ModalGeneric";
import Select from 'react-select';
import Swal from "sweetalert2";
import VerticalChart from "../components/Charts/VerticalChart";
import Utils from "../utils";
import PieChart from "../components/Charts/PieChart";

class Statistics extends Component {

  static defaultProps = {
    center: {
      lat: 39.6642211,
      lng: 20.85371
    },
    zoom: 15
  };

  constructor(props) {
    super(props);

    this.editRef = createRef(null)
    

    this.state = {
      statistics: [],
      sales: [],
      rents: [],
      sales_categories: [],
      rents_categories: [],
      loaded: false,
      show_search: false,
      filters: [],
      shape: [],
      showModal: false,
      search_title: '',
      saved_searches: [],
      search_polygon: null,
      loading: false
    };

    this.categories = [
      '',
      i18n.t('Κατοικίες'),
      i18n.t('Επαγγελματικά'),
      i18n.t('Γη'),
      i18n.t('Άλλο')
    ];

    this.searchTimeout = null;

    this.mapRef = React.createRef();
    this.getProperties = this.getProperties.bind(this);
  }

  componentDidMount() {
    
    uriHelper.init()
    uriHelper.set_component_view('statistics')
    

    this.fetchSavedSearches()
    this.getProperties(true)

    // this._drawLocalEdit()

    this.unlistenLocale = i18n.onChangeLocale(() => {
      this.forceUpdate();
    });
    
    
  }

  componentWillUnmount() {
    uriHelper.clear()
    this.unlistenLocale()
  }

  async getProperties(page_reset = true) {
    return new Promise((resolve) => {
        const uri = uriHelper.uri_to_string()
        
        if (this.searchTimeout) {
          window.clearTimeout(this.searchTimeout)
        }
        this.setState({
          loading: true
        }, () => {
          this.searchTimeout = setTimeout(() => {
            request.post(`${uri}`, {
              cordes: this.state.shape
            }).then((response) => {
              const stats = response.data.statistics.map(s => ({
                int_to: s.i,
                cat_basic_id: s.c,
                price: s.p,
                year: s.y,
                sq: s.s,
              }))
              const sales = stats.filter(s => parseInt(s.int_to) === 1)
              const sales_sum = []
              const sales_categories = {}
              var sales_price_per_sq_sum = 0;
              var sales_price_sum = 0;
              var sales_year_sum = 0;
              var sales_year_count = 0;
              var sales_total = sales.length;
  
              sales.forEach(s => {
  
                const pos = Math.ceil(s.price / 50000);
                if (pos === 0) return;
                const pos_index = pos * 50;
                const pos_index_text = pos_index >= 1000 ? `${Math.ceil(pos_index / 1000)}m` : `${pos_index}k`
                if (sales_sum[pos_index_text]) sales_sum[pos_index_text] = sales_sum[pos_index_text] + 1
                else sales_sum[pos_index_text] = 1
  
                const categories_index = this.categories[parseInt(s.cat_basic_id)];
                if (sales_categories[categories_index]) sales_categories[categories_index] = sales_categories[categories_index] + 1
                else sales_categories[categories_index] = 1
  
                if (s.sq > 0) {
                  sales_price_per_sq_sum += Math.round(parseInt(s.price) / parseInt(s.sq));
                }
                
                sales_price_sum += parseInt(s.price);
  
                if (s.year > 1900) {
                  sales_year_sum += parseInt(s.year);
                  sales_year_count++;
                }
                
              })
              console.log(sales_price_sum)
              
  
  
              const rents = stats.filter(s => parseInt(s.int_to) === 2)
              const rents_sum = {}
              const rents_categories = {}
              var rents_price_per_sq_sum = 0;
              var rents_price_sum = 0;
              var rents_year_sum = 0;
              var rents_year_count = 0;
              var rents_total = rents.length;
  
              rents.forEach(s => {
                const pos = Math.ceil(s.price / 100);
                if (pos === 0) return;
                const pos_index = `${pos * 100}€`;
  
                if (rents_sum[pos_index]) rents_sum[pos_index] = rents_sum[pos_index] + 1
                else rents_sum[pos_index] = 1
  
                const categories_index = this.categories[parseInt(s.cat_basic_id)];
                if (rents_categories[categories_index]) rents_categories[categories_index] = rents_categories[categories_index] + 1
                else rents_categories[categories_index] = 1
  
                rents_price_per_sq_sum += parseInt(s.price) / parseInt(s.sq);
                rents_price_sum += parseInt(s.price);
                if (s.year > 1900) {
                  rents_year_sum += parseInt(s.year);
                  rents_year_count++;
                }
              })

              console.log(Object.keys(rents_categories))
              
              const stateData = {
                statistics: stats,
                sales: Utils.sortPriceObject(sales_sum),
                rents: Utils.sortPriceObject(rents_sum),
                sales_categories: sales_categories,
                rents_categories: rents_categories,
                rents_price_per_sq_sum: rents_price_per_sq_sum,
                rents_price_sum: rents_price_sum,
                rents_year_sum: rents_year_sum,
                rents_year_count: rents_year_count,
                rents_total: rents_total,
  
                sales_price_per_sq_sum: sales_price_per_sq_sum,
                sales_price_sum: sales_price_sum,
                sales_year_sum: sales_year_sum,
                sales_year_count: sales_year_count,
                sales_total: sales_total,
                filters: response.data.filters,
                loading: false
              }
  
              this.setState({
                ...stateData
              }, () => {
                resolve(true)
              });
            });
          }, 100)
        })
        
    })
      
  }

  setShow(show) {
    this.setState({
      show_search: show
    })
  }

  removeFilter(key, value) {
    this.setState({
      filters: this.state.filters.filter(f => f.key !== key)
    })
  }

  // map edit methods 
  _onEditPath(e) {
    // console.log(e)
  }
  _onCreate = (e) => {
    this.setState({
      shape: e
    }, () => this.getProperties(true))
  }
  _onDeleted() {

  }

  saveSearchInit = () => {
    this.setState({
      showModal: true,
      search_title: ''
    })
  } 
  
  saveSearch = () => {
    const uri = uriHelper.uri_to_string()
  
    request.post('/saved_searches/statistics', {
      uri: uri,
      shape: this.state.shape,
      title: this.state.search_title
    }).then(response => {
      Swal.fire({
        title: i18n.t('success'),
        text: i18n.t('search_has_been_saved'),
        icon: 'success'
      }).then(rsp => {
        this.setState({
          showModal: false,
          search_title: ''
        })
      })
    })
  }

  fetchSavedSearches = () => {  
    request.get('/saved_searches/statistics').then(response => {
      this.setState({
        saved_searches: response.data
      })
    })
  }

  setSelectedSearch = (id) => {
    const selected_search = this.state.saved_searches.filter(s => s.id === id)[0]

    if (selected_search) {
      uriHelper.init_uri(selected_search.uri)
      uriHelper.set_uri()
      this.setState({
        shape: selected_search.shape,
        search_polygon: selected_search.shape
      }, () => {
        this.getProperties()
      })
    }
  }
    
  render() {

    return (
      <Fragment>
        <Header />
        <Leftnav />

        <div className="main-content right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0" style={{maxWidth: "100%"}}>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <Pagetitle 
                      title={i18n.t('statistics_page_title')}
                    >
                      <div className="search-controls">
                        <Select
                          className="select-element mr-5"
                          classNamePrefix="select"
                          placeholder={i18n.t('load_saved_search')}
                          
                          isSearchable={true}
                          name="categories"
                          options={this.state.saved_searches.map(s => {
                            return {
                              label: `${s.name} (${s.total} ${i18n.t('results')})`,
                              value: s.id
                            }
                          })}
                          onChange={(e) => {this.setSelectedSearch(e.value)}}
                        />
                        {
                          (this.state.filters.length > 1 || (this.state.shape && this.state.shape.length > 0)) &&
                          <button className='btn btn-secondary text-white mr-5 rounded-xl' onClick={() => this.saveSearchInit()}>
                            {i18n.t('save_search')}
                          </button>
                        }
                        <button className="btn btn-primary text-white mr-5 rounded-xl" onClick={() => this.setState({show_search: true})}>
                          <FontAwesomeIcon icon={faMagnifyingGlass} /> {i18n.t('filters')}
                        </button>
                      </div>
                      <ActiveFilters filters={this.state.filters} onChange={(key, value) => this.removeFilter(key, value)} />
                      

                      <ModalGeneric
                        show={this.state.showModal}
                        handleClose={() => this.setState({
                          showModal: false
                        })}
                        header={<h4>{i18n.t('save_search')}</h4>}
                        footer={<div className="card-body p-0 d-flex space-between open-house-footer align-center">
                              <div className="d-flex">
                                <button onClick={() => this.saveSearch()} className="bg-success btn-action btn-accept p-2 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-600">{i18n.t('save')}</button>
                                <button onClick={() => this.setState({
                          showModal: false
                        })}className="bg-grey btn-action btn-accept p-2 ms-2 z-index-1 rounded-3 font-xsssss text-uppercase fw-600">{i18n.t('button_cancel_request')}</button>
                              </div>
                            </div>}
                      >
                        <div className="card w-100 border-0 p-4 mb-3">
                          <div className="card-body mt-2 p-0">
                            <div className="form-group p-relative">
                              <label className="mb-2">{i18n.t('set_a_search_title')}</label>
                              <input 
                                type="text" 
                                className='form-control' 
                                onChange={(e) => this.setState({
                                  search_title: e.target.value
                                })}
                                placeholder={i18n.t('search_title')}
                                ref={this.searchRef}
                              />
                            </div>
                          </div>
                        </div>
                      </ModalGeneric>
                    </Pagetitle>
                  </div>
                  <div className="col-xl-6 ps-0 d-none d-xl-block">
                    <div className="card w-100 border-0 shadow-none rounded-3 border-0 mb-4 overflow-hidden h-100">
                      <InteractiveMap 
                        _onCreate={this._onCreate}
                        initialPolygon={this.state.search_polygon ? this.state.search_polygon : null}
                      />
                      
                    </div>
                  </div>
                  <div className="col-xl-6 chat-left scroll-bar properties-wrapper">
                    <SearchProperties
                      onchange={() => this.getProperties(true)}
                      setShow={(show) => this.setShow(show)}
                      show={this.state.show_search}
                    />
                    <div className="row ps-2 pe-2">
                      {
                        Object.keys(this.state.sales).length > 0 ?
                        <>
                          <h4>{i18n.t('sales')}</h4>
                          <VerticalChart 
                            title={''}
                            data={{
                              labels: Object.keys(this.state.sales),
                              datasets: [{
                                label: i18n.t('sum_of_sales_per_price_group'),
                                data: Object.values(this.state.sales) ,
                                backgroundColor: 'rgba(91, 201, 244, 0.8)',
                              }]
                            }}
                          />
                          <hr />
                        </> : ''
                      }
                      
                      {
                        Object.keys(this.state.rents).length > 0 ?
                        <>
                          <h4>{i18n.t('rents')}</h4>
                          <VerticalChart 
                            title={i18n.t('rents')}
                            data={{
                              labels: Object.keys(this.state.rents),
                              datasets: [{
                                label: i18n.t('sum_of_rents_per_price_group'),
                                data: Object.values(this.state.rents),
                                backgroundColor: 'rgba(91, 201, 244, 0.8)',
                              }]
                            }}
                          /> 
                          <hr />
                        </> : ''
                      }
                      <div className="row">
                        <div className="col-md-6">
                        
                          {
                          Object.keys(this.state.sales_categories).length > 0 ?
                            <>
                              <h4>{i18n.t('sales')}</h4>
                              <PieChart 
                                data={{
                                  labels: Object.keys(this.state.sales_categories),
                                  datasets: [{
                                    label: i18n.t('sum_of_rents_per_category'),
                                    data: Object.values(this.state.sales_categories),
                                    backgroundColor: [
                                      'rgba(255, 99, 132, 0.2)',
                                      'rgba(54, 162, 235, 0.2)',
                                      'rgba(255, 206, 86, 0.2)',
                                      'rgba(75, 192, 192, 0.2)',
                                    ],
                                    borderColor: [
                                      'rgba(255, 99, 132, 1)',
                                      'rgba(54, 162, 235, 1)',
                                      'rgba(255, 206, 86, 1)',
                                      'rgba(75, 192, 192, 1)',
                                    ],
                                    borderWidth: 1,
                                  }]
                                }}
                              />
                              <hr />
                            </> : ''
                          }
                        </div>
                        <div className="col-md-6">

                          {
                          Object.keys(this.state.rents_categories).length > 0 ?
                            <>
                              <h4>{i18n.t('rents')}</h4>
                              <PieChart 
                                data={{
                                  labels: Object.keys(this.state.rents_categories),
                                  datasets: [{
                                    label: i18n.t('sum_of_rents_per_category'),
                                    data: Object.values(this.state.rents_categories),
                                    backgroundColor: [
                                      'rgba(255, 99, 132, 0.2)',
                                      'rgba(54, 162, 235, 0.2)',
                                      'rgba(255, 206, 86, 0.2)',
                                      'rgba(75, 192, 192, 0.2)',
                                    ],
                                    borderColor: [
                                      'rgba(255, 99, 132, 1)',
                                      'rgba(54, 162, 235, 1)',
                                      'rgba(255, 206, 86, 1)',
                                      'rgba(75, 192, 192, 1)',
                                    ],
                                    borderWidth: 1,
                                  }]
                                }}
                              />
                              <hr />
                            </> : ''
                          }
                        </div>
                      </div>
                      <div className="row">
                          <h3>{i18n.t('statistics')}</h3>
                          {
                          Object.keys(this.state.sales_categories).length > 0 ? 
                          <div className="col-md-6">
                            <h3>{i18n.t('sales')}</h3>
                            <div className="statistics">
                              <div className="stat-row">
                                <span className="stat-name">{i18n.t('price_per_sq_median')}: </span>
                                <span className="stat-value">{
                                  (this.state.sales_price_per_sq_sum / this.state.sales_total).toFixed(2)
                                } € / s.q.</span>
                              </div>
                              <hr/>
                              <div className="stat-row">
                                <span className="stat-name">{i18n.t('price_median')}: </span>
                                <span className="stat-value">{
                                  (this.state.sales_price_sum / this.state.sales_total).toFixed(2)
                                } €</span>
                              </div>
                              <hr/>
                              <div className="stat-row">
                                <span className="stat-name">{i18n.t('construction_year_median')}: </span>
                                <span className="stat-value">{
                                  Math.round(this.state.sales_year_sum / this.state.sales_year_count)
                                }</span>
                              </div>
                            </div>
                          </div> : ''
                          }
                          {
                          Object.keys(this.state.rents_categories).length > 0 ? 
                          <div className="col-md-6">
                            <h3>{i18n.t('rents')}</h3>
                            <div className="statistics">
                              <div className="stat-row">
                                <span className="stat-name">{i18n.t('price_per_sq_median')}: </span>
                                <span className="stat-value">{
                                  (this.state.rents_price_per_sq_sum / this.state.rents_total).toFixed(2)
                                } € / s.q.</span>
                              </div>
                              <hr/>
                              <div className="stat-row">
                                <span className="stat-name">{i18n.t('price_median')}: </span>
                                <span className="stat-value">{
                                  (this.state.rents_price_sum / this.state.rents_total).toFixed(2)
                                } €</span>
                              </div>
                              <hr/>
                              <div className="stat-row">
                                <span className="stat-name">{i18n.t('construction_year_median')}: </span>
                                <span className="stat-value">{
                                  Math.round(this.state.rents_year_sum / this.state.rents_year_count)
                                }</span>
                              </div>
                            </div>
                          </div> : ''
                          }
                          
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <Rightchat />
        <Popupchat />
        <Appfooter /> 
      </Fragment>
    );
  }
}

export default Statistics;